export const quotaMoanGrid = params =>{
    if(params.value ==null){
        return '일시불'
    }else{
        switch(params.value){
            case '0' : return '일시불'
            case '00' : return '일시불'
            default : return params.value + '개월'
          }
    }
}