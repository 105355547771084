import { numberWithCommas } from "../numberWithCommas";
import {transStatus} from "../transStatus";

export const bankReportDetailExcel = (data) =>{

  var headers;
  var headerName;
  var newRowData;
  const xlsx = require( "xlsx" );

  
    headers = [
        "TID"                               
        ,"STMT_DT"                               
        ,"APP_DT"                               
        ,"MID"
        ,"GID"                               
        ,"TRX_ST_CD"                               
        ,"TR_AMT"                               
        ,"FEE"                               
        ,"VAT"                               
        ,"DPST_AMT"                               
    ];

    headerName = {
      'TID': 'TID',
      'STMT_DT': '입금일자',
      'APP_DT': '승인일자',
      'MID': 'MID',
      'GID': 'GID',
      'TRX_ST_CD': '상태',
      'TR_AMT': '승인금액',
      'FEE': '수수료',
      'VAT': 'VAT',
      'DPST_AMT': '지급액'
    }

  newRowData = data.map(rec => {
    return {
        'TID': rec.TID,
        'STMT_DT': rec.STMT_DT,
        'APP_DT': rec.APP_DT,
        'MID': rec.MID,
        'GID': rec.GID,
        'TRX_ST_CD': transStatus(rec.TRX_ST_CD),
        'TR_AMT': rec.TR_AMT,
        'FEE': rec.FEE,
        'VAT': rec.VAT,
        'DPST_AMT': rec.DPST_AMT
  }});

    newRowData.unshift(headerName);

    const book = xlsx.utils.book_new();
    const bankReporDetailtList = xlsx.utils.json_to_sheet( newRowData

, { header :headers , skipHeader : true } );

xlsx.utils.book_append_sheet( book,bankReporDetailtList);

xlsx.writeFile( book, '페이시스_입금상세내역.xlsx');
}
