import React  from 'react';

export const smMbsCdGrid = params => {
    switch(params.value){
      case '00' : return '일반'
      case 'A1' : return '영세'
      case 'B1' : return '중소1'
      case 'B2' : return '중소2'
      case 'B3' : return '중소3'
      default : return '일반'
    }
}