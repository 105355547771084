import {transStatus} from "../transStatus";
import {paymentTypeGrid} from "../grid/paymentTypeGrid";

export const agencyReportDetailExcel = (data) =>{

  let headers;
  let headerName;
  let newRowData;
  const xlsx = require( "xlsx" );

  
    headers = [
        "TID"
        ,"TR_DT"
        ,"APP_DT"
        ,"CO_NM"
        ,"MID"
        ,"GID"
        ,"TRX_ST_CD"
        ,"TR_AMT"
        ,"MID_FEE_PER"
        ,"VID_FEE_PER"
        ,"FEE"
        ,"VAT"
        ,"DPST_AMT"
    ];

    headerName = {
        'TID': 'TID',
        'TR_DT': '거래일자',
        'APP_DT': '승인일자',
        'CO_NM': '상점명',
        'MID': 'MID',
        'GID': 'GID',
        'TRX_ST_CD': '상태',
        'TR_AMT': '승인금액',
        "MID_FEE_PER":"수수료율",
        "VID_FEE_PER":"기준수수료율",
        'FEE': '수수료',
        'VAT': 'VAT',
        'DPST_AMT': '지급액'
    }

  newRowData = data.map(rec => {
    return {
        'TID': rec.TID,
        'TR_DT': rec.TR_DT,
        'APP_DT': rec.APP_DT,
        'CO_NM': rec.CO_NM,
        'MID': rec.MID,
        'GID': rec.GID,
        'TRX_ST_CD': transStatus(rec.TRX_ST_CD),
        'TR_AMT': rec.TR_AMT,
        'MID_FEE_PER': rec.MID_FEE_PER,
        'VID_FEE_PER': rec.VID_FEE_PER,
        'FEE': rec.FEE,
        'VAT': rec.VAT,
        'DPST_AMT': rec.DPST_AMT
  }});

    newRowData.unshift(headerName);

    const book = xlsx.utils.book_new();
    const agencyReporDetailtList = xlsx.utils.json_to_sheet( newRowData

, { header :headers , skipHeader : true } );

xlsx.utils.book_append_sheet( book,agencyReporDetailtList);

xlsx.writeFile( book, '페이시스_영업_상세내역.xlsx');
}
