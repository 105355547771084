import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useCookies} from "react-cookie";
import Swal from "sweetalert2";
import swal from "sweetalert";
import {useNavigate, Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {Card, Grid, Modal, Typography} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const TruncateText = ({ text, maxLength }) => {
    if (text.length <= maxLength) {
        return <span>{text}</span>;
    }

    const truncatedText = text.substring(0, maxLength) + '...';
    return <span title={text}>{truncatedText}</span>;
};

const Notice = ({ title, regDate }) => {
    return (
        <ListItemText
            primary={
            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                <TruncateText text={title} maxLength={30} />
                <span style={{fontSize : '13px'}}>{regDate}</span>
            </p>}>
        </ListItemText>
    );
};

const FAQ = ({ title, notiNm }) => {
    return (
        <ListItemText primary={
            <span>{"["+notiNm+"] "}<TruncateText text={title} maxLength={38-notiNm.length} /></span>}>
            }
        </ListItemText>
    );
};

export default function PostList() {
    const [postList, setPostList] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies();
    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        regDate: '',
        body: '',
        types:'',
    });

    const handleOpenModal = (content) => {
        setModalContent(prevContent => ({
            ...prevContent,
            NOTI_NM: content[0].NOTI_NM,
            title: content[0].TITLE,
            regDate: content[0].REG_DT,
            body: content[0].BODY.replaceAll('\n','<br />'),
            types: content[0].TYPES,
        }));
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleCellClicked = (params) => {
        const seq = params.seq;
        if (seq) {
            // Send axios request to get the data based on the seq value
            axios
                .post(
                    process.env.REACT_APP_API_HOST + '/mms/noticeList',
                    {
                        token: cookies.token,
                        userId: cookies.userid,
                        permissions: cookies.type,
                        oper: params.types == '공지사항'?'notice':'faq',
                        seq: seq,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                        },
                    }
                )
                .then(function (response) {
                    if (response.data.res_code !== '0000') {
                        swal({
                            text: response.data.res_msg,
                            button: {
                                text: '확인',
                            },
                        });
                        if (response.data.res_code === '1004') {
                            swal({
                                text: response.data.res_msg,
                                button: {
                                    text: '확인',
                                },
                            });
                            navigate('/login', { replace: false });
                        }
                    } else {
                        response.data.data[0][0].TYPES = params.types
                        handleOpenModal(response.data.data[0]);
                    }
                })
                .catch(function (error) {
                    swal({
                        text: '미지정 오류',
                        button: {
                            text: '확인',
                        },
                    });
                });
        }
    };

    useEffect(() => {
        axios
            .post(
                process.env.REACT_APP_API_HOST + "/mms/noticeList",
                {
                    token : cookies.token,
                    userId : cookies.userid,
                    permissions : cookies.type,
                    viewType : 'list'
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                })
            .then(response => {
                if (response.data.res_code != '0000') {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: '확인',
                        },
                    });
                    if (response.data.res_code == '1004') {
                        swal({
                            text: response.data.res_msg,
                            button: {
                                text: '확인',
                            },
                        });
                        navigate('/login', { replace: false });
                    }
                    Swal.close();
                } else {
                    setPostList(response.data.data);
                    Swal.close();
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <div className="App">
            <Card>
            <Grid>
                <Grid container justifyContent="space-between" alignItems="baseline">
                <Grid>
                <h1 style={{ marginLeft: '15px' }}>공지사항</h1>
                </Grid>
                <Grid>
                <Link to={{ pathname: '/app/notice', search: '?search=noticeList' }}>
                    <Button size="small" variant="contained" color="primary" style={{ marginRight: '15px' }}>더보기</Button>
                </Link>
                </Grid>
                </Grid>
                <List component="nav" aria-label="mailbox folders">
                {postList.length > 0 &&
                    postList[0].map(item => (
                        <ListItem button
                        style={{height:'42px',borderTop:'1px solid #dadada'}}
                        key={item.SEQ}
                        onClick={() => handleCellClicked({
                            seq: item.SEQ,
                            types: '공지사항',
                        })}>
                        <Notice
                            title={item.TITLE}
                            regDate={item.REG_DT}
                            seq={item.SEQ}
                            notiNm={item.NOTI_NM}
                            key={item.SEQ}
                        />
                        </ListItem>
                    ))}
                </List>
            </Grid>
            </Card>
            <br></br>
            <Card>
            <Grid>
                <Grid container justifyContent="space-between" alignItems="baseline">
                <Grid>
                <h1 style={{ marginLeft: '15px' }}>FAQ</h1>
                </Grid>
                <Grid>
                <Link to={{ pathname: '/app/notice', search: '?search=faqList' }} >
                    <Button size="small" variant="contained" color="primary" style={{ marginRight: '15px' }}>더보기</Button>
                </Link>
                </Grid>
                </Grid>
                <List component="nav" aria-label="mailbox folders">

                {postList.length > 1 &&
                    postList[1].map(item => (
                        <ListItem button
                        style={{height:'42px',borderTop:'1px solid #dadada'}}
                        key={item.SEQ}
                        onClick={() => handleCellClicked({
                            seq: item.SEQ,
                            types: 'FAQ',
                        })}>
                        <FAQ
                            title={item.TITLE}
                            notiNm={item.NOTI_NM}
                            key={item.SEQ}
                        />
                        </ListItem>
                    ))}
                </List>
            </Grid>
            </Card>
            <Modal open={openModal} onClose={handleCloseModal}>
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '70%',
                        maxHeight: '80%',
                        overflowY: 'auto',
                        backgroundColor: '#fff',
                        padding: '20px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                    }}
                >
                    <div
                        style={{
                            borderBottom : '2px solid #eee',
                            marginBottom: '15px',
                            paddingBottom: '10px'
                        }}>
                        <Typography variant="h2" style = {{fontWeight:'700'}}>
                            {modalContent.types}
                        </Typography>
                    </div>
                    <div
                        style={{
                            border: '1px solid #eee',
                            borderRadius: '4px',
                            backgroundColor: '#eee',
                            padding: '10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h4" style={{fontWeight:'700'}}>
                            {modalContent.title}
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: '#777' }}>
                            {modalContent.regDate}
                        </Typography>
                    </div>
                    <div
                        style={{
                            padding: '10px',
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: `<style>
                                table {
                                border-collapse: collapse;
                            }
                                th, td {
                                border: 1px solid black;
                                padding: 8px;
                            }
                            </style>` }} />
                        <div dangerouslySetInnerHTML={{ __html: modalContent.body }} />
                    </div>
                    <div style={{
                        textAlign: 'right', marginTop: '20px',paddingTop:'10px',borderTop:'1px solid #ddd'
                    }}>
                        <Button variant="contained" color="primary" onClick={handleCloseModal}>
                            닫기
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}