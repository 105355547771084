import React, { useState, useEffect } from 'react';
import {
    Card,
    List,
    ListItem,
    TablePagination,
    Divider,
} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl } from '@material-ui/core';
import { Button } from '@mui/material';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { display, fontSize, height, margin, padding } from '@mui/system';
import { dateTime } from '../../common/dateTime';
import { dateYYYYMMDD } from '../../common/dateYYYYMMDD';
import { numberWithCommas } from '../../common/numberWithCommas';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { deleteSpecialChar } from '../../common/deleteSpecialChar';
import ko from 'date-fns/locale/ko';
import { render } from '@testing-library/react';
import ReceiptButton from '../trans/TransView/ReceiptButton';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import SearchIcon from '@material-ui/icons/Search';
import { set } from 'date-fns';

const getFormatDate = (date) => {
    var year = date.getFullYear();
    var month = 1 + date.getMonth();
    month = month >= 10 ? month : '0' + month;
    var day = date.getDate();
    day = day >= 10 ? day : '0' + day;
    return year + '/' + month + '/' + day;
};

const SmsMain = () => {
   
    
   
    //navigate
    const navigate = useNavigate();

    const currentDate = new Date();
    //시작일자 ,종료일자
    const [startDate, setStartDate] = React.useState(getFormatDate(currentDate));
    const [endDate, setEndDate] = React.useState(getFormatDate(currentDate));
    const [products, setProducts] = useState([]);
    const [smsCount, setSmsCount] = useState(0);
    const [smsLists, setSmsLists] = useState([]);
    const [dateList, setDateList] = React.useState([{ value: 'all', lavel: '전체' }, { value: 'appreq', lavel: '결제요청' }, { value: 'appdt', lavel: '결제완료' },{ value: 'ccdt', lavel: '결제취소' },{ value: 'applmt', lavel: '결제만료'}]);
    const [dateType, setDateType] = useState('all');
    const [selectTypeText,setSelectTypeText] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    //선택하세요 값 Value
    const selectTypeList = (
        [{ value: 'all', lavel: '모두' }
          , { value: 'appno', lavel: '승인번호' }
          , { value: 'ordnm', lavel: '구매자' }
          , { value: 'goodsnm', lavel: '상품명' }
          , { value: 'memo', lavel: '메모' }
        ]);
    const [selectType, setSelectType] = useState('all');

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        selectSmsList(newPage);
    };

    function selectSmsListClick() {
        setRowsPerPage(0);
        setPage(0);
        selectSmsList(0);
    }


    //smslist조회 
    function selectSmsList(page) {
        if(selectType !='all' && (selectTypeText == '' || selectTypeText == null)){
            swal({
                text: '검색조건을 입력해주세요',
                button: {
                text: '확인',
                },
             });
             return false;
        }

        axios
            .post(
                process.env.REACT_APP_API_HOST + "/mms/smsList",
                {
                    userId : cookies.userid,
                    usrTypeId : cookies.id,
                    usrType:cookies.type,
                    dateType : dateType,
                    dateTo: deleteSpecialChar(endDate),
                    dateFrom: deleteSpecialChar(startDate),
                    mid: cookies.id,
                    selectTypeText : selectTypeText,
                    selectType : selectType,
                    iRows: (10 * page) + 10,
                    stRow: (10 * page),
                    token : cookies.token
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                }
            )
            .then(function (response) {
                setSmsCount(response.data.data.total);
                setSmsLists(response.data.data.list);
            })
            .catch(function (error) {
                Swal.close();
                swal({
                   text: '미지정 오류',
                   button: {
                   text: '확인',
                   },
                });
            });
    }

    const dateButton = (e) => {
        const date = new Date();
        const dates = new Date();
        if (e.target.value == '0') {
          date.setDate(date.getDate() - 1);
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(date));
        } else if (e.target.value == '1') {
          date.setDate(date.getDate());
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(date));
        } else if (e.target.value == '2') {
          date.setDate(date.getDate() - 7);
          dates.setDate(dates.getDate());
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(dates));
        } else if (e.target.value == '3') {
          date.setDate(date.getDate() - 31);
          dates.setDate(dates.getDate());
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(dates));
        }
      }

      function TextFieldValue(e){
          setSelectTypeText(e.target.value)
      }

      function menoDetail(meno){
        if(meno !== undefined && meno!== '' ){
            Swal.fire({
                text:meno,
            });
        }else{
            Swal.fire({
                text:'메모 없음'
            });
        }
      }

    useEffect(() => {
    }, []);


    return (
        <div>
            <Card>
                <Button variant="contained" onClick={() => {
                    navigate('/app/smspay', { replace: false });
                }} style={{ float: 'right' }}> SMS요청</Button>
            </Card> 
            <br></br>
            <Card>
                <FormControl sx={{ m: 1, width: 250 }}>
                    <Select size='small'
                        defaultValue={'all'}
                        value={dateList.value}
                        inputProps={{
                            id: 'selectDateType',
                        }}
                        onChange={(event) => {
                            setDateType(event.target.value);
                        }}
                        style={{ marginRight: '15px' }}
                    >
                             {dateList?.map((option ,idx) => {
                            return (
                                <MenuItem key={idx} value={option.value}>
                                    {option.lavel}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Card>
            <br></br>
            <Card>

            <div align="center">
                <div style={{margin:'10px'}}>
                <div style={{paddingRight:'10px',display:'inline-block'}}><Select 
                        size='small'  
                        defaultValue={'all'}
                        value={selectTypeList.value}
                        inputProps={{
                            id: 'selectSearchType',
                        }}
                        onChange={(event) => {
                            if(event.target.value =='all'){
                                setSelectTypeText('');
                            }
                            setSelectType(event.target.value);
                            
                        }}
                        >
                              {selectTypeList?.map((option,idxs) => {
                            return (
                                <MenuItem key={idxs} value={option.value}>
                                    {option.lavel}
                                </MenuItem>
                            );
                        })}
                        </Select>
                        </div>
                <div id="selectTypeDiv" style={{display:'inline-block'}}><TextField value={selectTypeText} onChange={TextFieldValue} id="selectTypeText"  variant="standard" size='small'/></div>
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                    <DatePicker
                        value={startDate}
                        inputFormat={"yyyy/MM/dd"}
                        mask={"____/__/__"}
                        onChange={(newValue) => {
                            setStartDate(getFormatDate(newValue));
                        }}
                        renderInput={(params) => <TextField style={{ width: '150px' }} size='small'{...params} />}
                    />
                </LocalizationProvider>
                ~
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                    <DatePicker
                        value={endDate}
                        inputFormat={"yyyy/MM/dd"}
                        mask={"____/__/__"}
                        onChange={(newValue) => {
                            setEndDate(getFormatDate(newValue));
                        }}
                        renderInput={(params) => <TextField
                            style={{ width: '150px' }} size='small'{...params} />}
                    />
                </LocalizationProvider>
                

        
                <br></br>
                <br></br>
                <Button size="small" style={{ marginLeft: '10px' }} value="0" onClick={dateButton} variant="outlined" color="primary">어제</Button>
                <Button size="small" style={{ marginLeft: '10px' }} value="1" onClick={dateButton} variant="outlined" color="primary">오늘</Button>
                <Button size="small" style={{ marginLeft: '10px' }} value="2" onClick={dateButton} variant="outlined" color="primary">일주일</Button>
                <Button size="small" style={{ marginLeft: '10px' }} value="3" onClick={dateButton} variant="outlined" color="primary">1개월</Button>
                <br></br>
                <br></br>
                <Button variant="contained" onClick={selectSmsListClick} style={{ width: '100%' }}> 조회</Button>
                </div>
            </Card>
            <br></br>
            <Card>
                {smsCount > 0 ? (
                    <List>
                        {smsLists.map((smsList, i) => (
                            <ListItem divider={i < smsLists.length - 1} key={i}>
                                <div style={{ width: '100%' }}>
                                    <div align="left" style={{ float: 'left', width: '50%' }}>
                                        {(function () {
                                            if (smsList.REQ_ST_CD === '0') {
                                                return <div style={{ color: 'blue', fontSize: '9' }}>SMS 결제요청</div>;
                                            } else if (smsList.REQ_ST_CD === '2') {
                                                return <div style={{ color: 'red' }}>SMS 요청취소</div>;
                                            } else if (smsList.REQ_ST_CD === '9') {
                                                return <div style={{ color: 'blue' }}>SMS 결제완료</div>;
                                            } else if (smsList.REQ_ST_CD === '10') {
                                                return <div style={{ color: 'red' }}>SMS 결제취소</div>;
                                            } else if (smsList.REQ_ST_CD === '11') {
                                                return <div style={{ color: 'green' }}>SMS 요청만료</div>;
                                            }
                                        })()}
                                    </div>
                                    <div align="right" style={{ float: 'right', width: '50%' }}>
                                        <div>
                                            {numberWithCommas(smsList.GOODS_AMT)}원
                                        </div>
                                    </div>
                                        <div align="right" style={{paddingBottom : '5px',paddingTop : '30px'}}>
                                        <button onClick={() => menoDetail(smsList.ORD_MSG)}>메모상세</button>
                                        </div>
                                    <div align="left" style={{ float: 'left', width: '30%' }}>
                                        <div style={{ fontSize: '0.8rem' }}>
                                            <div>메모</div>
                                            <div>상품명</div>
                                            <div>구매자</div>
                                            {(function () {
                                                if (smsList.REQ_ST_CD === '0') {
                                                    return <div>요청일자</div>;
                                                } else if (smsList.REQ_ST_CD === '2') {
                                                    return <div>요청일자</div>;
                                                } else if (smsList.REQ_ST_CD === '9') {
                                                    return <div>승인일자</div>;
                                                } else if (smsList.REQ_ST_CD === '10') {
                                                    return <div>취소일자</div>;
                                                } else if (smsList.REQ_ST_CD === '11') {
                                                    return <div>요청일자</div>;
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    <div align="right" style={{ float: 'right', width: '70%' ,textOverflow : 'ellipsis',overflow:'hidden'}}>
                                        <div style={{ fontSize: '0.8rem' , textOverflow : 'ellipsis' , whiteSpace : 'nowrap'}}>
                                        {(function () {
                                            if(smsList.ORD_MSG !== undefined && smsList.ORD_MSG !== '' ){
                                                return <div>{smsList.ORD_MSG}</div>
                                            }else{
                                                return <div>메모 없음</div>
                                            }
                                        })()}
                                        <div>{smsList.GOODS_NM}</div>
                                        <div>{smsList.ORD_NM}</div>
                                            {(function () {
                                                if (smsList.REQ_ST_CD === '0') {
                                                    return <div>{dateYYYYMMDD(smsList.REQ_DT)} {dateTime(smsList.REQ_TM)}</div>;
                                                } else if (smsList.REQ_ST_CD === '2') {
                                                    return <div>{dateYYYYMMDD(smsList.REQ_DT)} {dateTime(smsList.REQ_TM)}</div>;
                                                } else if (smsList.REQ_ST_CD === '9') {
                                                    return <div>{dateYYYYMMDD(smsList.APP_DT)} {dateTime(smsList.APP_TM)}</div>;
                                                } else if (smsList.REQ_ST_CD === '10') {
                                                    return <div>{dateYYYYMMDD(smsList.CC_DT)} {dateTime(smsList.CC_TM)}</div>;
                                                } else if (smsList.REQ_ST_CD === '11') {
                                                    return <div>{dateYYYYMMDD(smsList.REQ_DT)} {dateTime(smsList.REQ_TM)}</div>;
                                                }
                                            })()}
                                        </div>
                                    </div>

                                    <div align="left" style={{ float: 'left', width: '30%' }}>
                                        <div style={{ fontSize: '0.8rem' }}>
                                            {(function () {
                                                if (smsList.REQ_ST_CD === '0') {
                                                    return <div>결제기한</div>;
                                                } else if (smsList.REQ_ST_CD === '2') {
                                                    return <div>결제기한</div>;
                                                } else if (smsList.REQ_ST_CD === '9') {
                                                    return <div>승인번호</div>;
                                                } else if (smsList.REQ_ST_CD === '10') {
                                                    return <div>승인번호</div>;
                                                } else if (smsList.REQ_ST_CD === '11') {
                                                    return <div>결제기한</div>;
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    <div align="right" style={{ float: 'right', width: '70%' }}>
                                        <div style={{ fontSize: '0.8rem' }}>
                                            {(function () {
                                                if (smsList.REQ_ST_CD === '0') {
                                                    return <div>{dateYYYYMMDD(smsList.LMT_DT)} {dateTime(smsList.LMT_TM)}</div>;
                                                } else if (smsList.REQ_ST_CD === '2') {
                                                    return <div>{dateYYYYMMDD(smsList.LMT_DT)} {dateTime(smsList.LMT_TM)}</div>;
                                                } else if (smsList.REQ_ST_CD === '9') {
                                                    return <div>{smsList.APP_NO}</div>;
                                                } else if (smsList.REQ_ST_CD === '10') {
                                                    return <div>{smsList.APP_NO}</div>;
                                                } else if (smsList.REQ_ST_CD === '11') {
                                                    return <div>{dateYYYYMMDD(smsList.LMT_DT)} {dateTime(smsList.LMT_TM)}</div>;
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div align="center">
                                        {smsList.TRX_ST_CD < 3 ? <ReceiptButton  value={smsList.TID}/> : ''}
                                    </div>
                                </div>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <List>
                        <div style={{ textAlign: 'center' }}>SMS요청 내역이 존재하지 않습니다.</div>
                    </List>
                )}
                <Divider></Divider>
                <TablePagination
                    labelRowsPerPage=''
                    rowsPerPageOptions={[]}
                    component="div"
                    count={smsCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                />
            </Card>
        </div>
    );
};

export default SmsMain;