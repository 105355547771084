import React from 'react';
import Link from '@material-ui/core/Link';
import StyledLink from "./styledLink";

const Footer = () =>{
    return(
        <div style={{
            position: "relative",
            borderTop: "1px solid #DDD",
            marginTop: "30px",
        }}>
            <div style={{
                paddingBottom: "15px",
                paddingTop: "10px",
                paddingRight: "15px",
                paddingLeft: "25px",
                marginRight: "auto",
                marginLeft: "auto",
            }}>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginRight: "-15px",
                    marginLeft: "-15px"
                }}>
                    <div className="col-xl-10">
                        <StyledLink />
                    </div>
                </div>
            </div>
            <div style={{
                padding : "0 0 20px 10px",
                fontSize : "13px",
                lineHeight : "1.8",
            }}>
                <div style={{
                    paddingLeft: "15px",
                    marginRight: "auto",
                    marginLeft: "auto"
                }}>
                    <div style={{
                        marginTop : "0",
                        paddingTop : "0",
                        borderTop : "0",
                        marginBottom : "5px"
                    }}>
                        <div style={{
                            display: "block",
                            position: "relative",
                            width: "80px",
                            height: "auto",
                            textAlign:"center",
                            margin: "0 15px 0 0",
                            float: "left"
                        }}>
                            <img style={{
                                verticalAlign: "middle",
                                borderStyle: "none",
                                maxWidth: "100%",
                                height: "auto",
                                display: "block",
                                position: "relative",
                                borderRadius: "3px",
                                width: "80px",
                            }} src="/paysis.svg" alt="Image" />
                        </div>
                        <div style={{
                            position: "relative",
                            overflow: "hidden"
                        }}>
                            <div>
                                <h4 style={{
                                    margin: "0",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#444",
                                    lineHeight: "1.5",
                                    letterSpacing : "-1px"
                                }}>주식회사
                                    페이시스&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;대표&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; 박종식&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;서울
                                    성동구 성수일로 77 서울숲IT밸리
                                    714호&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;사업자등록번호:567-86-03090</h4>
                                <p style={{fontSize: "12px",marginTop:"0",marginBottom:"0"}}>대표번호 :
                                    070-4322-4809&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;PG서비스 :
                                    070-4322-4809&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;관리책임자 : help@paysis.co.kr</p>
                                <p style={{fontSize: "12px",marginTop:"0",marginBottom:"0",lineHeight: "16px"}}>Copyrights © 2023 PAYSIS. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;