import React from 'react';
import {
    makeStyles,
    CardHeader,
    Divider,
    Card
  } from '@material-ui/core';
  import clsx from 'clsx';
  import TextField from '@mui/material/TextField';
  import { Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material';
  import Button from '@mui/material/Button';
  import swal from 'sweetalert';
import { useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import Swal from 'sweetalert2';
import { Navigate ,useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { smMbsCdGrid } from '../../../common/grid/smMbsCdGrid';
  
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));

  

 



  const MsmbsInfo =({ className, ...rest }) => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [rowData,setRowData] = useState();
    const [textCono, setTextCono] = useState();
    const rowHeight = 40;
    const navigate = useNavigate();
    const classes = useStyles({});

    //사업자번호 조회
    function searchCono(cono){
      if(!cono){
        swals('사업자번호를 확인해주세요');
        return false;
      }
      const conos = cono;
      const arr = cono.split(",");
      
      if(arr.length > 10){
        swals('사업자번호 최대 10개까지 조회 가능합니다');
        return false;
      }

      for(var i=0;i<arr.length;i++){
          if(!checkCono(arr[i])){
              swals('사업자번호 : ' + arr[i]+' 유효하지 않습니다');
              return false;
          }
      }
      selectMsmbsSearch(arr);
    }
  
    //사업자번호 유효성체크
    function checkCono(cono){
        var numberMap = cono
    if(numberMap.length == 10){
      var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
      var chk = 0;
      
      keyArr.forEach(function(d, i){
        chk += d * numberMap[i];
      });
      
      chk += parseInt((keyArr[8] * numberMap[8])/ 10, 10);
      return(Math.floor(numberMap[9]) === ( (10 - (chk % 10) ) % 10))
    }
          return false;
    }
  
    //오류 파업창
    function swals(msg){
      swal({
          text: msg,
          button: {
              text: '확인',
          },
      });
      return false;
    }

  //우대사업자 번호 조회 API
  function selectMsmbsSearch(conos){
    Swal.showLoading();
    axios
    .post(
       process.env.REACT_APP_API_HOST + "/mms/msMbsSearch",
       {
          token : cookies.token,
          usrTypeId : cookies.id,
          usrType:cookies.type,
          userId : cookies.userid,
          cono : conos
       },
       {
          headers: {
             'Content-Type': 'application/json',
             'X-Requested-With': 'XMLHttpRequest',
          },
       }
    )
    .then(function (response) {
       if (response.data.res_code != '0000') {
          swal({
            text: response.data.res_msg,
            button: {
              text: '확인',
            },
          });
          navigate('/app/main', { replace: false });
          if (response.data.res_code == '1004') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            navigate('/login', { replace: false });
          }
          Swal.close();
        } else {
          setRowData(response.data.data);
          Swal.close();
        }
      }).catch(function (error) {
        Swal.close();
        swal({
          text: '미지정 오류',
          button: {
            text: '확인',
          },
        });
    });
    }

  return (   
    <>
    <br></br>
    <Card>
    <Table size="small" aria-label="a dense table">
      <TableBody>
    <TableRow>
            <TableCell>
              사업자번호
            </TableCell>
            <TableCell>
              <TextField id="textCono"   
              onChange={(event) =>{setTextCono(event.target.value)}} 
              onKeyPress={(ev) => {
              if(ev.key === 'Enter'){
                searchCono(textCono);
              }}}
              variant="outlined" 
              size="small" />
            </TableCell>
            <TableCell>
            <Button size="medium" variant="outlined" onClick={() => searchCono(textCono)} color="primary">조회</Button>
            </TableCell>
            </TableRow>
            </TableBody>
      </Table>
    </Card>
    <br></br>
    <Card>
      <h5>사업자번호 상태 (계속,휴업,폐업) 확인 불가합니다. </h5>
      <h5>사업자번호 다중 조회시 최대 10개 까지 "," 구분으로 가능합니다.</h5>
      <h5>Ex : 2068190716,3153400737</h5>
    </Card>
    <br></br>
    <Card>
        <CardHeader title="우대사업자 정보" />
        <Divider />
        <div className="ag-theme-alpine" style={{height: 500, width: '100%'}}>
           <AgGridReact rowHeight={rowHeight}
               rowData={rowData}>
               <AgGridColumn  width={150} headerName = "사업자번호" field="CO_NO"></AgGridColumn>
               <AgGridColumn  width={150} headerName = "영중소등급" field="SM_MBS_CD"  cellRenderer={smMbsCdGrid}></AgGridColumn>
           </AgGridReact>
       </div>
      </Card>
      </>
  );
  }

  export default MsmbsInfo;