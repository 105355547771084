import React,{useState,useEffect,useContext} from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ko from "date-fns/locale/ko";
import swal from "sweetalert";
import Swal from "sweetalert2";
import axios from "axios";
import { useCookies } from 'react-cookie';
import { Navigate ,useNavigate } from 'react-router-dom';
import GroupVidVidGidMidInfo from "../../../common/GroupVidVidGidMidInfo";
import { MyContext } from "../../../App";
import {
  Card,
} from '@material-ui/core';
import Button from '@mui/material/Button';
import { Grid } from "@material-ui/core";
import { numberWithCommas } from "../../../common/numberWithCommas";
import Notices from "./notices";
import ModalNotice from "../../notice/modalNotice";

const Main = () =>{
    const { selectInfoList, setSelectInfoList } = useContext(MyContext);

    const getFormatDate = (date) => {
        var year = date.getFullYear();
        var month = 1 + date.getMonth();
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;
        return year + month;
    };

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [rowData,setRowData] = useState([]);

    const [calendarEvent,setCalendarEvent] = useState([]);

    const [holidays, setHolidays] = useState({});
    const [memoData, setMemoData] = useState({});
    
    var regex = /[^0-9]/g;   

    function mainCalendar() {
      Swal.showLoading();
   
      var selectMonth = document.querySelector('.fc-toolbar-title').innerHTML.replace(regex,"");
        if(selectMonth.length < 6){
          selectMonth = selectMonth.substring(0,4) + '0' + selectMonth.substring(4,5);
        }  
        axios
              .post(
                 process.env.REACT_APP_API_HOST + "/mms/mainCalendar",
                 {
                    token : cookies.token,
                    userId : cookies.userid,
                    usrTypeId : cookies.id,
                    usrType:cookies.type,
                    l1_vid : selectInfoList.l1Vid,
                    l2_vid : selectInfoList.l2Vid,
                    l3_vid : selectInfoList.l3Vid,
                    mid : selectInfoList.mid,
                    selectMonth :selectMonth
                 },
                 {
                    headers: {
                       'Content-Type': 'application/json',
                       'X-Requested-With': 'XMLHttpRequest',
                    },
                 }
              )
              .then(function (response) {
                if (response.data.res_code != '0000') {
                  swal({
                    text: response.data.res_msg,
                    button: {
                      text: '확인',
                    },
                  });
                  if (response.data.res_code == '1004') {
                    swal({
                      text: response.data.res_msg,
                      button: {
                        text: '확인',
                      },
                    });
                    navigate('/login', { replace: false });
                  }
                  Swal.close();
                } else {
                  customData(response.data.data);
                  Swal.close();
                }
              }).catch(function (error) {
                Swal.close();
                swal({
                  text: '미지정 오류',
                  button: {
                    text: '확인',
                  },
                });
              });
      }

    function getHolidays() {
        Swal.showLoading();

        axios
            .post(
                process.env.REACT_APP_API_HOST + "/mms/holiday",
                {
                    token : cookies.token,
                    userId : cookies.userid,
                    usrTypeId : cookies.id,
                    usrType:cookies.type,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                }
            )
            .then(function (response) {
                if (response.data.res_code != '0000') {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: '확인',
                        },
                    });
                    if (response.data.res_code == '1004') {
                        swal({
                            text: response.data.res_msg,
                            button: {
                                text: '확인',
                            },
                        });
                        navigate('/login', { replace: false });
                    }
                    Swal.close();
                } else {
                    setHolidays(response.data.data.reduce((acc, item) => {
                        acc[item.DAY] = item.DAY;
                        return acc;
                    }, {}));
                    setMemoData(response.data.data.reduce((acc, item) => {
                        acc[item.DAY] = item.MEMO;
                        return acc;
                    }, {}));
                    Swal.close();
                }
            }).catch(function (error) {
            Swal.close();
            swal({
                text: '미지정 오류',
                button: {
                    text: '확인',
                },
            });
        });
    }

    function isHoliday(date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const dateString = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
        return holidays[dateString]?.includes(dateString);
    }

    function CustomDayCellContent(props) {
        const { date, view } = props;

        let textColor = '#000000';
        let memo = undefined;
        let textSize = '13px';

        if (date.getDay() === 6) {
            textColor = '#0000FF';
        }
        if (date.getDay() === 0 || isHoliday(date)) {
            textColor = '#FF0000';
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const dateString = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
            memo = memoData[dateString];
            if (memo && memo.length > 5) {
                textSize = '10px';
            }
        }

        return (
            <>
            {memo !== undefined  && (
            <span style={{ color: textColor, textAlign: "left", fontSize: textSize}}>
                    {memo}&nbsp;
            </span>
            )}
            <span style={{ color: textColor, textAlign: "right", fontSize: "13px" }}>
                {date.getDate()}일
            </span>
            </>
        );
    }

    function customData(data){
        var datas=[];
        if(data.length == 0){
          setRowData(0);
        }else{
          for(var i=0;i<data.length;i++){
            datas.push({date:data[i].STMT_DT,title:numberWithCommas(data[i].DPST_AMT)+'원'});
          }
          setRowData(datas);
        }
      }
    
      useEffect(() => {
          getHolidays();
      },[]);

    return (
        <>
        <ModalNotice />
        <div style={{float: 'right'}}>
        <Button size="small" variant="contained" onClick={mainCalendar} color="primary">조회</Button>
        </div>
        <br></br>
        <br></br>
        <div>
             <GroupVidVidGidMidInfo/>
        </div>
        <br></br>
        <Grid container spacing={2}>
        <Grid item sm={6}>
               <Card>
        <div className="App">
                <FullCalendar
                  id="fullcalendar"
                    locale={ko}
                    height={650}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    dayCellContent={CustomDayCellContent}
                    events={
                      rowData
                      }
                />
              </div>
              </Card>
          </Grid>
            <Grid item sm={6}>
            <Notices />
            </Grid>
            </Grid>
      </>
    );
    
};

export default Main;