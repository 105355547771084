import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import {Card, CardHeader, Divider, FormControl, Grid, Modal, Typography} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

function Notice() {
    const [cookies, setCookie, removeCookie] = useCookies();
    const navigate = useNavigate();
    const rowHeight = 40;
    const [rowData, setRowData] = useState([]);
    const [rowDataTotal, setRowDataTotal] = useState({});

    const [openModal, setOpenModal] = useState(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchValue = searchParams.get('search');

    const [modalContent, setModalContent] = useState({
        NOTI_NM: '',
        TITLE: '',
        REG_DT: '',
        BODY: '',
        TYPES: '',
    });

    const contList = [
        { value: 'noticeList', label: '공지사항' },
        { value: 'faqList', label: 'FAQ' },
    ];

    const [contType, setContType] = useState('noticeList');

    const handleOpenModal = (content) => {
        setModalContent(prevContent => ({
            ...prevContent,
            NOTI_NM: content[0].NOTI_NM,
            TITLE: content[0].TITLE,
            REG_DT: content[0].REG_DT,
            BODY: content[0].BODY.replaceAll('\n','<br />'),
            TYPES: contType === 'faqList' ? 'FAQ' : '공지사항',
        }));
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const faqCtgr = [
        {value : 'all', label: '전체'},
        {value : '01', label: '단말기'},
        {value : '02', label: '운영'},
        {value : '03', label: '정산/세금계산서'},
        {value : '04', label: '취소'},
        {value : '05', label: '한도/보증보험'},
    ]
    const [faqType, setFaqType] = useState('all');
    const [ctgrDisplay, setCtgrDisplay] =useState(false);

    const noticeCtgr = [
        {value : 'all', label: '전체'},
        {value : '1', label: '무이자할부'},
        {value : '2', label: '뉴스'},
        {value : '3', label: '장애공지'},
        {value : '4', label: '작업공지'},
        {value : '5', label: '이벤트'},
        {value : '6', label: '서비스변경/추가'},
        {value : '7', label: '업데이트'},
        {value : '8', label: '보도자료'},
        {value : '9', label: '기타'},
    ]
    const [noticeType, setNoticeType] = useState('all');

    const getData = (params) => {
        Swal.showLoading();
        axios
            .post(
                process.env.REACT_APP_API_HOST + '/mms/noticeList',
                {
                    token: cookies.token,
                    userId: cookies.userid,
                    permissions: cookies.type,
                    ctgrDtl: faqType,
                    notiCd: noticeType,
                    oper: params == null ? contType : params ,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                }
            )
            .then(function (response) {
                if (response.data.res_code !== '0000') {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: '확인',
                        },
                    });
                    if (response.data.res_code === '1004') {
                        swal({
                            text: response.data.res_msg,
                            button: {
                                text: '확인',
                            },
                        });
                        navigate('/login', { replace: false });
                    }
                    Swal.close();
                } else {
                    setRowData(response.data.data[0]);
                    setRowDataTotal(response.data.total);
                    Swal.close();
                }
            })
            .catch(function (error) {
                Swal.close();
                swal({
                    text: '미지정 오류',
                    button: {
                        text: '확인',
                    },
                });
            });
    };

    useEffect((params) => {
        if (searchValue!=null) {
            setContType(searchValue);
            params = searchValue;
            if(searchValue === 'faqList'){
                setCtgrDisplay(true);
            }
            if(searchValue === 'noticeList'){
                setCtgrDisplay(false);
            }
        }
        setNoticeType('all');
        setFaqType('all');
        getData(params);
    }, [location]);

    const handleCellClicked = (params) => {
        const seq = params.node.data.SEQ;
        if (seq) {
            axios
                .post(
                    process.env.REACT_APP_API_HOST + '/mms/noticeList',
                    {
                        token: cookies.token,
                        userId: cookies.userid,
                        permissions: cookies.type,
                        oper: contType=='noticeList' ? 'notice' : 'faq',
                        seq: seq,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                        },
                    }
                )
                .then(function (response) {
                    if (response.data.res_code !== '0000') {
                        swal({
                            text: response.data.res_msg,
                            button: {
                                text: '확인',
                            },
                        });
                        if (response.data.res_code === '1004') {
                            swal({
                                text: response.data.res_msg,
                                button: {
                                    text: '확인',
                                },
                            });
                            navigate('/login', { replace: false });
                        }
                    } else {
                        handleOpenModal(response.data.data[0]);
                    }
                })
                .catch(function (error) {
                    swal({
                        text: '미지정 오류',
                        button: {
                            text: '확인',
                        },
                    });
                });
        }
    };

    return (
        <div>
            <Card>
                <CardHeader title="고객지원" />
                <FormControl sx={{ m: 1, width: 250 }}>
                    <Select
                        size="small"
                        defaultValue="noticeList"
                        value={contType}
                        inputProps={{
                            id: 'selectContType',
                        }}
                        onChange={(event) => {
                            if (event.target.value == 'noticeList') {
                                setCtgrDisplay(false);
                                setContType(event.target.value);
                            }else if(event.target.value == 'faqList'){
                                setCtgrDisplay(true);
                                setContType(event.target.value);
                            }
                        }}
                        style={{ marginLeft: '15px' ,marginBottom: '15px'}}
                    >
                        {contList?.map((option) => {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: 250 }}>
                    {ctgrDisplay ? (
                        <Select
                            size="small"
                            defaultValue=""
                            value={faqType}
                            inputProps={{
                                id: 'selectFaqType',
                            }}
                            onChange={(event) => {
                                setFaqType(event.target.value);
                            }}
                            style={{ marginLeft: '15px' ,marginBottom: '15px'}}
                        >
                            {faqCtgr?.map((option) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    ) : (
                        <Select
                            size="small"
                            defaultValue=""
                            value={noticeType}
                            inputProps={{
                                id: 'selectNoticeType',
                            }}
                            onChange={(event) => {
                                setNoticeType(event.target.value);
                            }}
                            style={{ marginLeft: '15px' ,marginBottom: '15px'}}
                        >
                            {noticeCtgr?.map((option) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                </FormControl>
                <div style={{ float: 'right' }}>
                    <Button size="small" variant="contained" style={{ marginRight: '15px' }} onClick={() => getData(null)} color="primary">
                        조회
                    </Button>
                </div>
            </Card>
            <br />
            <Card>
                <Divider />
                <div className="ag-theme-alpine" style={{ height: '70vh', width: '100%' }}>
                    <AgGridReact
                        rowHeight={rowHeight}
                        defaultColDef={{ resizable: true }}
                        debounceVerticalScrollbar={true}
                        enableCellTextSelection={true}
                        pagination={true}
                        paginationPageSize={14}
                        rowData={rowData}
                        onCellClicked={handleCellClicked}
                        getRowStyle={() => {
                            return { cursor: 'pointer'};
                        }}
                    >
                        <AgGridColumn width={150} headerName="공지구분" field="NOTI_NM"></AgGridColumn>
                        <AgGridColumn width={900} headerName="제목" field="TITLE"></AgGridColumn>
                        <AgGridColumn width={150} headerName="작성일" field="REG_DT"></AgGridColumn>
                        <AgGridColumn width={0} field="SEQ" hide></AgGridColumn>
                    </AgGridReact>
                </div>
            </Card>
            <Modal open={openModal} onClose={handleCloseModal}>
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '70%',
                        maxHeight: '80%',
                        overflowY: 'auto',
                        backgroundColor: '#fff',
                        padding: '20px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                    }}
                >
                    <div
                        style={{
                            borderBottom : '2px solid #eee',
                            marginBottom: '15px',
                            paddingBottom: '10px'
                        }}>
                        <Typography variant="h2" style = {{fontWeight:'700'}}>
                            {modalContent.TYPES}
                        </Typography>
                    </div>
                    <div
                        style={{
                            border: '1px solid #eee',
                            borderRadius: '4px',
                            backgroundColor: '#eee',
                            padding: '10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h4" style={{fontWeight:'700'}}>
                            {modalContent.TITLE}
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: '#777' }}>
                            {modalContent.REG_DT}
                        </Typography>
                    </div>
                    <div
                        style={{
                            padding: '10px',
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: `<style>
                                table {
                                border-collapse: collapse;
                            }
                                th, td {
                                border: 1px solid black;
                                padding: 8px;
                            }
                            </style>` }} />
                        <div dangerouslySetInnerHTML={{ __html: modalContent.BODY }} />
                    </div>

                    <div style={{
                        textAlign: 'right', marginTop: '20px',paddingTop:'10px',borderTop:'1px solid #ddd'
                    }}>
                        <Button variant="contained" color="primary" onClick={handleCloseModal}>
                            닫기
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Notice;
