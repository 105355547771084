export const dateYYYYMMDDGrid  = params => {
	if(params.value == null){
		return '-';
	}
	if(params.value === '총합'){
		return params.value;
	}
	var year = params.value.substring(0, 4);
	var month = params.value.substring(4, 6);
	var day = params.value.substring(6, 8);
	return year + '/' + month + '/' + day;
}
