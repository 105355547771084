export const TaxReportExcel = (data,trDt,type) => {
    
    var headers;
    var headerName;
    var newRowData;
    const xlsx = require("xlsx");


    headers = [
        ,"TR_DT"                               
          ,"MID"                               
          ,"CAT_ID"                               
          ,"CO_NO"                               
          ,"CO_NM"    
          ,"TOTAL_AMT"                           
          ,"TOTAL_FEE"
          ,"TOTAL_VAT"                               
          ,"APP_AMT" 
          ,"APP_FEE"                              
          ,"APP_VAT"                               
          ,"APP_CNT"                               
          ,"CC_AMT"                               
          ,"CC_FEE"                               
          ,"CC_VAT"                               
          ,"CC_CNT"                                                       
      ];
  
      headerName = {
        'TR_DT': '거래일시',
        'MID': 'MID',
        'CAT_ID': '단말기번호',
        'CO_NO': '사업자번호',
        'CO_NM':'상호명',
        'TOTAL_AMT': '합계 금액',
        'TOTAL_FEE':'합계 공금가액',
        'TOTAL_VAT': '합계 부가세',
        'APP_AMT': '승인 금액',
        'APP_FEE': '승인 공급가액',
        'APP_VAT': '승인 부가세',
        'APP_CNT': '승인 건수',
        'CC_AMT': '취소 금액',
        'CC_FEE': '취소 공급가액',
        'CC_VAT': '취소 부가세',
        'CC_CNT': '취소 건수',
      }
      
      if('a' == type){
        newRowData = data.map(rec => {
          return {
            'TR_DT': trDt,
            'MID': rec.MID,
            'CAT_ID': rec.CAT_ID,
            'CO_NO': rec.CO_NO,
            'CO_NM':rec.CO_NM,
            'TOTAL_AMT': rec.TOTAL_AMT,
            'TOTAL_FEE': rec.TOTAL_FEE,
            'TOTAL_VAT': rec.TOTAL_VAT,
            'APP_AMT': rec.APP_AMT,
            'APP_FEE': rec.APP_FEE,
            'APP_VAT': rec.APP_VAT,
            'APP_CNT': rec.APP_CNT,
            'CC_AMT': rec.CC_AMT,
            'CC_FEE': rec.CC_FEE,
            'CC_VAT': rec.CC_VAT,
            'CC_CNT': rec.CC_CNT,
          }
        })
      }else{
        newRowData = data.map(rec => {
          return {
            'TR_DT': rec.TR_DT,
            'MID': rec.MID,
            'CAT_ID': rec.CAT_ID,
            'CO_NO': rec.CO_NO,
            'CO_NM':rec.CO_NM,
            'TOTAL_AMT': rec.TOTAL_AMT,
            'TOTAL_FEE': rec.TOTAL_FEE,
            'TOTAL_VAT': rec.TOTAL_VAT,
            'APP_AMT': rec.APP_AMT,
            'APP_FEE': rec.APP_FEE,
            'APP_VAT': rec.APP_VAT,
            'APP_CNT': rec.APP_CNT,
            'CC_AMT': rec.CC_AMT,
            'CC_FEE': rec.CC_FEE,
            'CC_VAT': rec.CC_VAT,
            'CC_CNT': rec.CC_CNT,
          }
        })
      }

    newRowData.unshift(headerName);

    const book = xlsx.utils.book_new();
    const taxReportList = xlsx.utils.json_to_sheet( newRowData, { header :headers , skipHeader : true } );

    xlsx.utils.book_append_sheet( book,taxReportList);

    xlsx.writeFile( book, '페이시스_부가세신고.xlsx');
}