import React, { useState ,useEffect ,useRef ,useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Card,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableRow,
    makeStyles
  } from '@material-ui/core';
import GroupVidVidGidMidInfo from '../../../common/GroupVidVidGidMidInfo'
import Button from '@mui/material/Button';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import MidInfoDetailButton from './MidInfoDetailButton';
import axios from 'axios';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useCookies } from 'react-cookie';
import { checkPgGrid } from '../../../common/grid/checkPgGrid';
import { mbsUseFlgGrid } from '../../../common/grid/mbsUseFlgGrid';
import { CheckUseFlgGrid } from '../../../common/grid/CheckUseFlgGrid';
import { MyContext } from '../../../App';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { Navigate ,useNavigate } from 'react-router-dom';
import { FormControl } from '@material-ui/core';
import { dateYYYYMMDDGrid } from '../../../common/grid/dateYYYYMMDDGrid';
import { smMbsCdGrid } from '../../../common/grid/smMbsCdGrid';
import { midInfoExcel } from '../../../common/excel/midInfoExcel';


const useStyles = makeStyles(({
    root: {},
  }));

const MidInfo =({ className, ...rest }) => {
    
    const [selectTypeList,setSelectTypeList] =useState([{}]); 
    //선택하세요 값 Value
    const [selectType, setSelectType] = useState('select');
    
    const { selectInfoList, setSelectInfoList } = useContext(MyContext);
    const classes = useStyles({});
    const rowHeight = 40;
    const [rowData,setRowData] = useState();
    const [cookies, setCookie, removeCookie] = useCookies();
    const navigate = useNavigate();

    //권한 show hide
    const [l1vidDisplay , setl1vidDisplay] = React.useState(false);
    const [l2vidDisplay , setl2vidDisplay] = React.useState(false);
    const [l3vidDisplay , setl3vidDisplay] = React.useState(false);
     
    
    //권한에 따라 show hide 설정
    function styleDisplay(usrType){
        if(usrType == 4 ){
          setl1vidDisplay(true);
          setSelectTypeList(
            [{value : 'select' ,lavel : '선택하세요'}
            ,{value : 'cono' ,lavel : '사업자번호'}
            ,{value :'l2vid',lavel :'대리점'}
            ,{value :'l3vid',lavel :'딜러'}
            ,{value :'l2vidNm',lavel :'대리점명'}
            ,{value :'l3vidNm',lavel :'딜러명'}
            ,{value :'mid',lavel :'MID'}
            ,{value :'gid',lavel :'GID'}
            ,{value :'catId',lavel :'단말기번호'}
          ]);
        }else if( usrType == 3 ){
          setl1vidDisplay(true);
          setl2vidDisplay(true);
          setSelectTypeList(
            [{value : 'select' ,lavel : '선택하세요'}
            ,{value : 'cono' ,lavel : '사업자번호'}
            ,{value :'l3vid',lavel :'딜러'}
            ,{value :'l3vidNm',lavel :'딜러명'}
            ,{value :'mid',lavel :'MID'}
            ,{value :'gid',lavel :'GID'}
            ,{value :'catId',lavel :'단말기번호'}
          ]); 
        }else if( usrType == 1 ){
          setl1vidDisplay(true);
          setl2vidDisplay(true);
          setl3vidDisplay(true);
          setSelectTypeList(
            [{value : 'select' ,lavel : '선택하세요'}
            ,{value : 'cono' ,lavel : '사업자번호'}
            ,{value :'mid',lavel :'MID'}
            ,{value :'gid',lavel :'GID'}
            ,{value :'catId',lavel :'단말기번호'}
          ]); 
        }else{
          setSelectTypeList([{value : 'select' ,lavel : '선택하세요'}
          ,{value : 'cono' ,lavel : '사업자번호'}
          ,{value :'mid',lavel :'MID'}
          ,{value :'gid',lavel :'GID'}
          ,{value :'vid',lavel :'총판'}
          ,{value :'l2vid',lavel :'대리점'}
          ,{value :'l3vid',lavel :'딜러'}
          ,{value :'vidNm',lavel :'총판명'}
          ,{value :'l2vidNm',lavel :'대리점명'}
          ,{value :'l3vidNm',lavel :'딜러명'}
          ,{value :'catId',lavel :'단말기번호'}
        ]); 
        }
    }

    function selectMidInfoList(){
      Swal.showLoading();
        axios
        .post(
           process.env.REACT_APP_API_HOST + "/mms/selectMidInfoList",
           {
              token : cookies.token,
              userId : cookies.userid,
              usrTypeId : cookies.id,
              usrType:cookies.type,
              l1_vid : selectInfoList.l1Vid,
              l2_vid : selectInfoList.l2Vid,
              l3_vid : selectInfoList.l3Vid,
              mid : selectInfoList.mid,
              selectType : selectType,
              textSelectType : document.getElementById('textSelectType').value
           },
           {
              headers: {
                 'Content-Type': 'application/json',
                 'X-Requested-With': 'XMLHttpRequest',
              },
           }
        )
        .then(function (response) {
          if (response.data.res_code != '0000') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            if (response.data.res_code == '1004') {
              swal({
                text: response.data.res_msg,
                button: {
                  text: '확인',
                },
              });
              navigate('/login', { replace: false });
            }
            Swal.close();
          } else {
            setRowData(response.data.data)
            Swal.close();
          }
        }).catch(function (error) {
          swal({
            text: '미지정 오류',
            button: {
              text: '확인',
            },
          });
        });
      };

    //엑셀 다운로드
      function excelDownLoad(){
      midInfoExcel(rowData,cookies.type);
    }

    //가맹점 정보 가져오기
    useEffect(() => {
      styleDisplay(cookies.type);
    },[]);

   
    return (
        <form
        className={clsx(classes.root, className)}
        {...rest}
      >
        <div style={{float: 'right'}}>
        <Button onClick={selectMidInfoList} size="small" variant="contained" color="primary">조회</Button>
        <Button size="small" variant="contained" onClick={excelDownLoad} color="success">excel</Button>
        </div>
        <br></br>
        <br></br>
        <GroupVidVidGidMidInfo/>
        <br></br>
        <br></br>
        <Card>
        {/* 선택하세요 */}
        <FormControl  sx={{ m: 1, width: 250 }}>
        <Select size='small'
            defaultValue={'select'}
            value={selectTypeList.value}
            inputProps={{
              id: 'selectDateType',
            }}
            onChange={(event) =>{
              setSelectType(event.target.value);
              }}
              style={{marginLeft:'30px'}}
          >
              {selectTypeList?.map(option => {
          return (
          <MenuItem key={option} value={option.value}>{option.lavel}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
        <TextField  id="textSelectType" style={{marginLeft:'30px'}} size="small" variant="outlined" />
      </Card>
          <br></br>
      <Card>
        <CardHeader title="가맹점정보" />
        <Divider />
        <div className="ag-theme-alpine" style={{height: 520, width: '100%'}}>
           <AgGridReact rowHeight={rowHeight}
             frameworkComponents={{
                midInfoDetailButton: MidInfoDetailButton,
            }}
            defaultColDef={{ resizable: true }}
            debounceVerticalScrollbar={true}
            enableCellTextSelection={true}     
           pagination={true}
           paginationPageSize={10}
               rowData={rowData}>
               <AgGridColumn  width={150} headerName = "사업자번호" field="CO_NO"></AgGridColumn>
               <AgGridColumn  width={220} headerName = "상호" field="CO_NM"></AgGridColumn>
               <AgGridColumn  width={140} headerName = "MID" field="MID"></AgGridColumn>
               <AgGridColumn  width={140} headerName = "GID" field="GID"></AgGridColumn>
               <AgGridColumn  width={140} headerName = "단말기번호" field="CAT_ID"></AgGridColumn>
               <AgGridColumn  width={140} headerName = "수수료" field="FEE"></AgGridColumn>
               <AgGridColumn  width={100} headerName = "수기" field="MANUAL_PAY_FLG" cellRenderer={CheckUseFlgGrid}></AgGridColumn>
               <AgGridColumn  width={100} headerName = "SMS" field="SMS_PAY_FLG" cellRenderer={CheckUseFlgGrid}></AgGridColumn>
               <AgGridColumn  width={100} headerName = "정산주기" field="STMT_CYCLE_CD"></AgGridColumn>
               <AgGridColumn  width={80} headerName = "PG사" field="APP_VAN1_CD" cellRenderer={checkPgGrid}></AgGridColumn>
               <AgGridColumn width={120} headerName = "영중소 등급" field="SM_MBS_CD" cellRenderer={smMbsCdGrid}></AgGridColumn>
               <AgGridColumn width={150} headerName = "영중소 사용여부" field="MS_USE_FLG" cellRenderer={CheckUseFlgGrid}></AgGridColumn>
               <AgGridColumn hide={l1vidDisplay}  width={140} headerName = "총판" field="L1_VID"></AgGridColumn>
               <AgGridColumn hide={l1vidDisplay}  width={140} headerName = "총판명" field="VGRP_NM"></AgGridColumn>
               <AgGridColumn hide={l2vidDisplay}  width={140} headerName = "대리점" field="L2_VID"></AgGridColumn>
               <AgGridColumn hide={l2vidDisplay}  width={140} headerName = "대리점명" field="VGRP_NM2"></AgGridColumn>
               <AgGridColumn hide={l3vidDisplay}  width={140} headerName = "딜러" field="L3_VID"></AgGridColumn>
               <AgGridColumn hide={l3vidDisplay}  width={140} headerName = "딜러명" field="VGRP_NM3"></AgGridColumn>
               <AgGridColumn width={150} headerName = "등록일" field="REG_DNT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
               <AgGridColumn width={150} headerName = "상세보기" field="MID" cellRenderer="midInfoDetailButton"></AgGridColumn>
           </AgGridReact>
       </div>
      </Card>
        </form>
    );

}

MidInfo.propTypes = {
  className: PropTypes.string
};

export default MidInfo;