import React, { useState ,useContext ,useEffect} from 'react';
import {
    Card,
  } from '@material-ui/core';

  import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import GroupVidVidGidMidInfo from '../../../common/GroupVidVidGidMidInfo'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import { MyContext } from '../../../App';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

import { monthRange } from '../../../common/monthRange';
import { getFormatMonth } from '../../../common/getFormatMonth';
import { dateYYYYMMDDGrid } from '../../../common/grid/dateYYYYMMDDGrid';
import { numberWithCommasGrid } from '../../../common/grid/numberWithCommasGrid';
import { Navigate ,useNavigate } from 'react-router-dom';
import { TaxReportExcel } from '../../../common/excel/taxReportExcel';
import ko from 'date-fns/locale/ko';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const TaxReport =() => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const { selectInfoList, setSelectInfoList } = useContext(MyContext);
  //그리도 싸이즈
  const rowHeight = 35;
  //부가세신고 토탈 그리드
  const [rowData,setRowData] = useState();
  //부가세신고 월별  그리드
  const [monthRowData,setMonthRowData] = useState();
//부가세신고 월별  그리드
  const [dayRowData,setDayRowData] = useState();
  const currentDate = new Date();
  //시작일자 ,종료일자
  const [startDate, setStartDate] = React.useState(getFormatMonth(currentDate));
  const [endDate, setEndDate] = React.useState(getFormatMonth(currentDate));
  const dateList = [{ value: 'appdt', lavel: '거래일자' }];
  const [selectTypeList,setSelectTypeList] =useState([{value : 'select' ,lavel : '선택하세요'},{value : 'cono' ,lavel : '사업자번호'},{value :'mid',lavel :'MID'},{value :'catid',lavel :'단말기번호'}]); 
   //권한 show hide
  const [l1vidDisplay, setl1vidDisplay] = React.useState(false);
  const [l2vidDisplay, setl2vidDisplay] = React.useState(false);
  const [l3vidDisplay, setl3vidDisplay] = React.useState(false);
  const [selectType, setSelectType] = useState('select');
  const [monthShow , setMonthShow] =useState(false);
  const [dayShow , setDayShow] =useState(false);
  const [trDt,setTrDt] = useState('');

    const taxListDetailDayData = (params) =>{
      Swal.showLoading();
      axios
            .post(
               process.env.REACT_APP_API_HOST + "/mms/surTaxDetailDayList",
                {
                    token : cookies.token,
                    userId : cookies.userid,
                    usrTypeId : cookies.id,
                    usrType:cookies.type,
                    mid : params.data.MID,
                    yyyymm : params.data.TR_DT,
                },
               {
                  headers: {
                     'Content-Type': 'application/json',
                     'X-Requested-With': 'XMLHttpRequest',
                  },
               }
            )
            .then(function (response) {
              if (response.data.res_code != '0000') {
                swal({
                  text: response.data.res_msg,
                  button: {
                    text: '확인',
                  },
                });
                if (response.data.res_code == '1004') {
                  swal({
                    text: response.data.res_msg,
                    button: {
                      text: '확인',
                    },
                  });
                  navigate('/login', { replace: false });
                }
                Swal.close();
              } else {
                if(response.data.cnt == 0){
                  swal({
                    text: "데이터가 존재하지 않습니다.",
                    button: {
                      text: '확인',
                    },
                  });
                  setDayShow(false);
                }else{
                  setDayShow(true);
                }
                setDayRowData(response.data.data)
                Swal.close();
              }
            }).catch(function (error) {
              Swal.close();
              swal({
                text: '미지정 오류',
                button: {
                  text: '확인',
                },
              });
            });
    }
 
    const taxListDetailMonthData = (params) =>{
      setDayShow(false);
      Swal.showLoading();
      axios
            .post(
               process.env.REACT_APP_API_HOST + "/mms/surTaxDetailMonthList",
                {
                    token : cookies.token,
                    userId : cookies.userid,
                    usrTypeId : cookies.id,
                    usrType:cookies.type,
                    mid : params.data.MID,
                    startDate : startDate,
                    endDate : endDate,
                    selectType : selectType,
                },
               {
                  headers: {
                     'Content-Type': 'application/json',
                     'X-Requested-With': 'XMLHttpRequest',
                  },
               }
            )
            .then(function (response) {
              if (response.data.res_code != '0000') {
                swal({
                  text: response.data.res_msg,
                  button: {
                    text: '확인',
                  },
                });
                if (response.data.res_code == '1004') {
                  swal({
                    text: response.data.res_msg,
                    button: {
                      text: '확인',
                    },
                  });
                  navigate('/login', { replace: false });
                }
                Swal.close();
              } else {
                if(response.data.cnt == 0){
                  swal({
                    text: "데이터가 존재하지 않습니다.",
                    button: {
                      text: '확인',
                    },
                  });
                  setMonthShow(false);
                }else {
                  setMonthShow(true);
                }
                setMonthRowData(response.data.data)
                Swal.close();
              }
            }).catch(function (error) {
              Swal.close();
              swal({
                text: '미지정 오류',
                button: {
                  text: '확인',
                },
              });
            });
    }


    const taxListData = () => {
      let limitDay = monthRange(startDate, endDate);
      if (limitDay > 6) {
        swal({
          text: '시작일자와(과) 종료일자의 간격은 6개월 이내여야 합니다.',
          button: {
            text: '확인',
          },
        });
        return false;
      }
      setMonthShow(false);
      setDayShow(false);
      Swal.showLoading();
      axios
            .post(
               process.env.REACT_APP_API_HOST + "/mms/surTaxList",
               {
                  token : cookies.token,
                  userId : cookies.userid,
                  usrTypeId : cookies.id,
                  usrType:cookies.type,
                  l1_vid: selectInfoList.l1Vid,
                  l2_vid: selectInfoList.l2Vid,
                  l3_vid: selectInfoList.l3Vid,
                  mid: selectInfoList.mid,
                  startDate : startDate,
                  endDate : endDate,
                  selectType : selectType,
                  textSelectType : document.getElementById('textSelectType').value
               },
               {
                  headers: {
                     'Content-Type': 'application/json',
                     'X-Requested-With': 'XMLHttpRequest',
                  },
               }
            )
            .then(function (response) {
              if (response.data.res_code != '0000') {
                swal({
                  text: response.data.res_msg,
                  button: {
                    text: '확인',
                  },
                });
                if (response.data.res_code == '1004') {
                  swal({
                    text: response.data.res_msg,
                    button: {
                      text: '확인',
                    },
                  });
                  navigate('/login', { replace: false });
                }
                Swal.close();
              } else {
                if(response.data.cnt == 0){
                  swal({
                    text: "데이터가 존재하지 않습니다.",
                    button: {
                      text: '확인',
                    },
                  });
                 
                }
                setRowData(response.data.data)
                setTrDt(response.data.trDt);
                Swal.close();
              }
            }).catch(function (error) {
              Swal.close();
              swal({
                text: '미지정 오류',
                button: {
                  text: '확인',
                },
              });
            });
    }

    function getTrdt(){
      return trDt;
    }

    function detailText(){
      return    "<div >상세보기</div>"
    }

    function excelDownLoad(){
      TaxReportExcel(rowData,trDt,"a");
    }

    function excelMonthDownLoad(){
      TaxReportExcel(monthRowData,"","m");
    }

    function excelDayDownLoad(){
      TaxReportExcel(dayRowData,"","d");
    }

     //권한에 따라 show hide 설정
  function styleDisplay(usrType) {
    if (usrType == 4) {
      setl1vidDisplay(true);
    } else if (usrType == 3) {
      setl1vidDisplay(true);
      setl2vidDisplay(true);
    } else if (usrType == 1) {
      setl1vidDisplay(true);
      setl2vidDisplay(true);
      setl3vidDisplay(true);
    }
  }

    useEffect(() => {
    styleDisplay(cookies.type);
    },[]);

    return (
        <form>
        <div style={{float: 'right'}}>
        <Button  size="small" variant="contained" color="primary" onClick={taxListData}>
           조회
        </Button>
        </div>
        <br></br>
        <br></br>
        <GroupVidVidGidMidInfo />
        <br></br>
        <Card>
        <Select size='small'
            //  maxDate={currentDate}
            defaultValue={'appdt'}
            value={dateList.value}
            inputProps={{
              id: 'selectDateType',
            }}
            style={{ marginRight: '15px' }}
          >
            {dateList?.map(option => {
              return (
                <MenuItem key={option} value={option.value}>{option.lavel}</MenuItem>
              );
            })}
          </Select>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
          <DatePicker
            views={['year', 'month']}
            value={startDate}
            inputFormat={"yyyy/MM"}
            mask={"______"}
            onChange={(newValue) => {
              setStartDate(getFormatMonth(newValue));
            }}
            renderInput={(params) => <TextField style={{ width: '150px' }} size='small'{...params} />}
          />
        </LocalizationProvider>
        ~
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
          <DatePicker
            //  maxDate={currentDate}
            views={['year', 'month']}
            value={endDate}
            inputFormat={"yyyy/MM"}
            mask={"______"}
            onChange={(newValue) => {
              setEndDate(getFormatMonth(newValue));
            }}
            renderInput={(params) => <TextField
              style={{ width: '150px' }} size='small'{...params} />}
          />
        </LocalizationProvider>

            {/* 선택하세요 */}
          <FormControl  sx={{ m: 1, width: 250 }}>
          <Select size='small'
                defaultValue={'select'}
                value={selectTypeList.value}
                inputProps={{
                  id: 'selectDateType',
                }}
                onChange={(event) =>{
                  setSelectType(event.target.value);
                  }}
                  style={{marginLeft:'30px'}}
              >
                 {selectTypeList?.map(option => {
              return (
              <MenuItem key={option} value={option.value}>
                  {option.lavel}
              </MenuItem>
                );
             })}
            </Select>
            </FormControl>
            <TextField  id="textSelectType"  style={{marginLeft:'30px'}} size="small" variant="outlined" />
        </Card>

        <br></br>
    
        <Card>
          <div style={{textAlign :'right'}}>
        <Button   size="small" variant="contained" color="success" onClick={excelDownLoad}>
           EXCEL
        </Button>
        </div>
        <div className="ag-theme-alpine" style={{height: 260, width: '100%'}}>
           <AgGridReact rowHeight={rowHeight}
             frameworkComponents={{

            }}
            defaultColDef={{ resizable: true }}
            debounceVerticalScrollbar={true}
           pagination={true}
           enableCellTextSelection={true}
           paginationPageSize={4}
               rowData={rowData}>
                <AgGridColumn  width={150} headerName = "거래 일시" field="TR_DT" cellRenderer={getTrdt} ></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "MID" field="MID" ></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "단말기번호" field="CAT_ID" ></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "사업자번호" field="CO_NO" ></AgGridColumn> 
                <AgGridColumn  width={200} headerName = "상호명" field="CO_NM" ></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "합계 금액" field="TOTAL_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "합계 공급가액" field="TOTAL_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "합계 부가세" field="TOTAL_VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
                {/* <AgGridColumn  width={120} headerName = "승인 금액" field="APP_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "승인 공급가액" field="APP_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "승인 부가세" field="APP_VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "승인 건수" field="APP_CNT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "취소 금액" field="CC_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "취소 공급가액" field="CC_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={120}  headerName = "취소 부가세" field="CC_VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn>               
               <AgGridColumn  width={100}  headerName = "취소 건수" field="CC_CNT" cellRenderer={numberWithCommasGrid}></AgGridColumn>          */}
               <AgGridColumn  width={100}  headerName = "상세보기"  cellRenderer={detailText} onCellClicked={(params) => taxListDetailMonthData(params)}></AgGridColumn>         
           </AgGridReact>
            </div>
          </Card>
            <br></br>
          {monthShow ? 
          <Card>
               <div style={{textAlign :'right'}}>
              <Button   size="small" variant="contained" color="success" onClick={excelMonthDownLoad}>
                EXCEL
              </Button>
              </div>
        <div className="ag-theme-alpine" id="monthGrid" style={{height: 330, width: '100%'}}>
           <AgGridReact rowHeight={rowHeight}
             frameworkComponents={{

            }}
           pagination={true}
           enableCellTextSelection={true}
           paginationPageSize={12}
               rowData={monthRowData}>
                <AgGridColumn  width={100} headerName = "거래 일시" field="TR_DT" ></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "MID" field="MID" ></AgGridColumn> 
                {/* <AgGridColumn  width={120} headerName = "사업자번호" field="CO_NO" ></AgGridColumn> 
                <AgGridColumn  width={200} headerName = "사업자명" field="CO_NM" ></AgGridColumn>  */}
                <AgGridColumn  width={120} headerName = "합계 금액" field="TOTAL_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "합계 공급가액" field="TOTAL_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "합계 부가세" field="TOTAL_VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "승인 금액" field="APP_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "승인 공급가액" field="APP_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "승인 부가세" field="APP_VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={100} headerName = "승인 건수" field="APP_CNT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "취소 금액" field="CC_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "취소 공급가액" field="CC_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={120}  headerName = "취소 부가세" field="CC_VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn>               
               <AgGridColumn  width={100}  headerName = "취소 건수" field="CC_CNT" cellRenderer={numberWithCommasGrid}></AgGridColumn>         
               <AgGridColumn  width={100}  headerName = "상세보기"  cellRenderer={detailText} onCellClicked={(params) => taxListDetailDayData(params)}></AgGridColumn>    
           </AgGridReact>
            </div>
          </Card>
         : ''}
          <br></br>
          {dayShow ? 
          <Card>
              <div style={{textAlign :'right'}}>
              <Button   size="small" variant="contained" color="success" onClick={excelDayDownLoad}>
                EXCEL
              </Button>
              </div>
        <div className="ag-theme-alpine" id="dayGird" style={{height: 400, width: '100%'}}>
           <AgGridReact rowHeight={rowHeight}
             frameworkComponents={{

            }}
           pagination={true}
           enableCellTextSelection={true}
           paginationPageSize={31}
               rowData={dayRowData}>
                <AgGridColumn  width={110} headerName = "거래 일시" field="TR_DT"  cellRenderer={dateYYYYMMDDGrid}></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "MID" field="MID" ></AgGridColumn> 
                {/* <AgGridColumn  width={120} headerName = "사업자번호" field="CO_NO" ></AgGridColumn> 
                <AgGridColumn  width={200} headerName = "사업자명" field="CO_NM" ></AgGridColumn>  */}
                <AgGridColumn  width={120} headerName = "합계 금액" field="TOTAL_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "합계 공급가액" field="TOTAL_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "합계 부가세" field="TOTAL_VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "승인 금액" field="APP_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
                <AgGridColumn  width={120} headerName = "승인 공급가액" field="APP_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "승인 부가세" field="APP_VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "승인 건수" field="APP_CNT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "취소 금액" field="CC_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn> 
               <AgGridColumn  width={120} headerName = "취소 공급가액" field="CC_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={120}  headerName = "취소 부가세" field="CC_VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn>               
               <AgGridColumn  width={100}  headerName = "취소 건수" field="CC_CNT" cellRenderer={numberWithCommasGrid}></AgGridColumn>         
           </AgGridReact>
            </div>
          </Card>
        :""}
          </form>
    );

}

export default TaxReport;