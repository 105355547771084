import React, { useState, useEffect } from 'react';
import {
    Card,
    List,
    ListItem,
    TablePagination,
    Divider,
} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl } from '@material-ui/core';
import { Button } from '@mui/material';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { fontSize } from '@mui/system';
import { dateTime } from '../../common/dateTime';
import { dateYYYYMMDD } from '../../common/dateYYYYMMDD';
import { numberWithCommas } from '../../common/numberWithCommas';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { deleteSpecialChar } from '../../common/deleteSpecialChar';
import ko from 'date-fns/locale/ko';
import { render } from '@testing-library/react';
import ReceiptButton from '../trans/TransView/ReceiptButton';
import Swal from 'sweetalert2';
import swal from 'sweetalert';

const getFormatDate = (date) => {
    var year = date.getFullYear();
    var month = 1 + date.getMonth();
    month = month >= 10 ? month : '0' + month;
    var day = date.getDate();
    day = day >= 10 ? day : '0' + day;
    return year + '/' + month + '/' + day;
};

const PayMentMain = () => {
    //navigate
    const navigate = useNavigate();

    const currentDate = new Date();
    //시작일자 ,종료일자
    const [startDate, setStartDate] = React.useState(getFormatDate(currentDate));
    const [endDate, setEndDate] = React.useState(getFormatDate(currentDate));
    const [products, setProducts] = useState([]);
    const [manualpayCount, setManualpayCount] = useState(0);
    const [manualpayLists, setManualpayLists] = useState([]);
    const [dateList, setDateList] = React.useState([ { value: 'appdt', lavel: '승인일자' }, { value: 'ccdt', lavel: '취소일자' }]);
    const [dateType, setDateType] = useState('appdt');
    const [selectTypeText,setSelectTypeText] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    //선택하세요 값 Value
    const selectTypeList = (
        [{ value: 'all', lavel: '모두' }
          , { value: 'appno', lavel: '승인번호' }
          , { value: 'ordnm', lavel: '구매자' }
          , { value: 'goodsnm', lavel: '상품명' }
        ]);
    const [selectType, setSelectType] = useState('all');

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        selectManualpayList(newPage);
    };

    function TextFieldValue(e){
        setSelectTypeText(e.target.value)
    }

    function selectManualpayListClick() {
        setRowsPerPage(0);
        setPage(0);
        selectManualpayList(0);
    }


    //수기결제조회 
    function selectManualpayList(page) {
        if(selectType !='all' && (selectTypeText == '' || selectTypeText == null)){
            swal({
                text: '검색조건을 입력해주세요',
                button: {
                text: '확인',
                },
             });
             return false;
        }
        Swal.showLoading();
        axios
            .post(
                process.env.REACT_APP_API_HOST + "/mms/manualpayList",
                {
                    userId : cookies.userid,
                    usrTypeId : cookies.id,
                    usrType:cookies.type,
                    dateType : dateType,
                    dateTo: deleteSpecialChar(endDate),
                    dateFrom: deleteSpecialChar(startDate),
                    selectType : selectType,
                    selectTypeText : selectTypeText,
                    mid: cookies.id,
                    iRows: (10 * page) + 10,
                    stRow: (10 * page),
                    token : cookies.token
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                }
            )
            .then(function (response) {
                setManualpayCount(response.data.data.total);
                setManualpayLists(response.data.data.list);
                Swal.close();
            })
            .catch(function (error) {
                Swal.close();
                swal({
                   text: '미지정 오류',
                   button: {
                   text: '확인',
                   },
                });
                Swal.close();
            });
    }

    const dateButton = (e) => {
        const date = new Date();
        const dates = new Date();
        if (e.target.value == '0') {
          date.setDate(date.getDate() - 1);
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(date));
        } else if (e.target.value == '1') {
          date.setDate(date.getDate());
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(date));
        } else if (e.target.value == '2') {
          date.setDate(date.getDate() - 7);
          dates.setDate(dates.getDate());
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(dates));
        } else if (e.target.value == '3') {
          date.setDate(date.getDate() - 31);
          dates.setDate(dates.getDate());
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(dates));
        }
      }

    useEffect(() => {
        selectManualpayList(page);
    }, []);


    return (
        <div>
            <Card>
                <Button variant="contained" onClick={() => {
                    navigate('/app/manualpay', { replace: false });
                }} style={{ float: 'right' }}> 수기결제 요청</Button>
            </Card>
            <br></br>
            <Card>
                <FormControl sx={{ m: 1, width: 250 }}>
                    <Select size='small'
                        defaultValue={'appdt'}
                        value={dateList.value}
                        inputProps={{
                            id: 'selectDateType',
                        }}
                        onChange={(event) => {
                            setDateType(event.target.value);
                        }}
                        style={{ marginRight: '15px' }}
                    >
                        {dateList?.map((option,idx) => {
                            return (
                                <MenuItem key={idx} value={option.value}>
                                    {option.lavel}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

            </Card>
            <br></br>
            <Card>
            <div align="center">
                <div style={{margin:'10px'}}>
                <div style={{paddingRight:'10px',display:'inline-block'}}><Select 
                        size='small'  
                        defaultValue={'all'}
                        value={selectTypeList.value}
                        inputProps={{
                            id: 'selectSearchType',
                        }}
                        onChange={(event) => {
                            if(event.target.value =='all'){
                                setSelectTypeText('');
                            }
                            setSelectType(event.target.value);
                            
                        }}
                        >
                              {selectTypeList?.map((option,idxs) => {
                            return (
                                <MenuItem key={idxs} value={option.value}>
                                    {option.lavel}
                                </MenuItem>
                            );
                        })}
                        </Select>
                        </div>
                <div id="selectTypeDiv" style={{display:'inline-block'}}><TextField value={selectTypeText} onChange={TextFieldValue} id="selectTypeText"  variant="standard" size='small'/></div>
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                    <DatePicker
                        value={startDate}
                        inputFormat={"yyyy/MM/dd"}
                        mask={"____/__/__"}
                        onChange={(newValue) => {
                            setStartDate(getFormatDate(newValue));
                        }}
                        renderInput={(params) => <TextField style={{ width: '150px' }} size='small'{...params} />}
                    />
                </LocalizationProvider>
                ~
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                    <DatePicker
                        value={endDate}
                        inputFormat={"yyyy/MM/dd"}
                        mask={"____/__/__"}
                        onChange={(newValue) => {
                            setEndDate(getFormatDate(newValue));
                        }}
                        renderInput={(params) => <TextField
                            style={{ width: '150px' }} size='small'{...params} />}
                    />
                </LocalizationProvider>
        
                <br></br>
                <br></br>
                <Button size="small" style={{ marginLeft: '10px' }} value="0" onClick={dateButton} variant="outlined" color="primary">어제</Button>
                <Button size="small" style={{ marginLeft: '10px' }} value="1" onClick={dateButton} variant="outlined" color="primary">오늘</Button>
                <Button size="small" style={{ marginLeft: '10px' }} value="2" onClick={dateButton} variant="outlined" color="primary">일주일</Button>
                <Button size="small" style={{ marginLeft: '10px' }} value="3" onClick={dateButton} variant="outlined" color="primary">1개월</Button>
                <br></br>
                <br></br>
                <Button variant="contained" onClick={selectManualpayListClick} style={{ width: '100%' }}> 조회</Button>
                </div>
            </Card>
            <br></br>
            <Card>
                {manualpayCount > 0 ? (
                    <List>
                        {manualpayLists.map((manualpayList, i) => (
                            <ListItem divider={i < manualpayLists.length - 1} key={i}>
                                <div style={{ width: '100%' }}>
                                    <div align="left" style={{ float: 'left', width: '50%' }}>
                                        {(function () {
                                            if (manualpayList.TRX_ST_CD === '0') {
                                                return <div style={{ color: 'blue', fontSize: '9' }}>승인</div>;
                                            } else if (manualpayList.TRX_ST_CD === '1') {
                                                return <div style={{ color: 'red' }}>매입 전 취소</div>;
                                            } else if (manualpayList.TRX_ST_CD === '2') {
                                                return <div style={{ color: 'red' }}>매입 후 취소</div>;
                                            }
                                        })()}
                                    </div>
                                    <div align="right" style={{ float: 'right', width: '50%' }}>
                                        <div>
                                            {numberWithCommas(manualpayList.GOODS_AMT)}원
                                        </div>
                                    </div>
                                    <br></br><br></br>
                                    <div align="left" style={{ float: 'left', width: '30%' }}>
                                        <div style={{ fontSize: '0.8rem' }}>
                                            <div>상품명</div>
                                            <div>구매자</div>
                                            <div>승인일자</div>
                                            {(function () {
                                                if (manualpayList.TRX_ST_CD !== '0') {
                                                    return <div>취소일자</div>;
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    <div align="right" style={{ float: 'right', width: '70%' ,textOverflow : 'ellipsis',overflow:'hidden',fontSize : '0.8rem'}}>
                                        <div>{manualpayList.GOODS_NM}</div>
                                        <div>{manualpayList.ORD_NM}</div>
                                        <div>{dateYYYYMMDD(manualpayList.APP_DT)} {dateTime(manualpayList.APP_TM)}</div>
                                            {(function () {
                                              if (manualpayList.TRX_ST_CD !== '0') {
                                                return <div>{dateYYYYMMDD(manualpayList.CC_DT)} {dateTime(manualpayList.CC_TM)}</div>;
                                            }
                                            })()}
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div align="center">
                                    <ReceiptButton  value={manualpayList.TID}/>
                                    </div>
                                </div>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <List>
                        <div style={{ textAlign: 'center' }}>수기결제 내역이 존재하지 않습니다.</div>
                    </List>
                )}
                <Divider></Divider>
                <TablePagination
                    labelRowsPerPage=''
                    rowsPerPageOptions={[]}
                    component="div"
                    count={manualpayCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                />
            </Card>
        </div>
    );
};

export default PayMentMain;