import React from 'react';
import {
  Container,
  makeStyles,
  Grid
} from '@material-ui/core';
import Page from '../../../components/Page';
import Msmbsinfo from './MsmbsInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const MsmbsView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="우대사업자 조회"
    >
      <Container maxWidth={false}>
      <Grid
          // container
          // spacing={3}
          // justify='center'
        >
         <Grid
            item
            lg={6}
            // sm={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <Msmbsinfo/>
          </Grid>
          </Grid>
      </Container>
    </Page>
  );
};

export default MsmbsView;
