import {checkPg} from '../checkPg';
import {smMbsCd} from '../smMbsCd';
import {checkUseFlg} from '../checkUseFlg';
export const midInfoExcel = (data,usrType) =>{

  var headers;
  var headerName;
  var newRowData;
  const xlsx = require( "xlsx" );

  if(usrType == 1){
    headers = [
        ,"CO_NO"
        ,"CO_NM"
        ,"MID"
        ,"GID"
        ,"CAT_ID"
        ,"FEE"
        ,"MANUAL_PAY_FLG"
        ,"SMS_PAY_FLG"
        ,"STMT_CYCLE_CD"
        ,"APP_VAN1_CD"
        ,"SM_MBS_CD"
        ,"MS_USE_FLG"
        // ,"USE_FLG"
        ,"REG_DNT"
    ];

    headerName = {
      'CO_NO': '사업자번호',
      'CO_NM': '상호',
      'MID': 'MID',
      'GID': 'GID',
      'CAT_ID': '단말기번호',
      'FEE': '수수료',
      'MANUAL_PAY_FLG': '수기',
      'SMS_PAY_FLG': 'SMS',
      'STMT_CYCLE_CD': '정산주기',
      'APP_VAN1_CD': 'PG사',
      'SM_MBS_CD': '영중소 등급',
      'MS_USE_FLG': '영중소 사용여부',
      // 'USE_FLG': '사용여부',
      'REG_DNT': '등록일'
    }

  newRowData = data.map(rec => {
    return {
      'CO_NO': rec.CO_NO,
      'CO_NM': rec.CO_NM,
      'MID': rec.MID,
      'GID': rec.GID,
      'CAT_ID': rec.CAT_ID,
      'FEE': rec.FEE,
      'MANUAL_PAY_FLG': checkUseFlg(rec.MANUAL_PAY_FLG),
      'SMS_PAY_FLG': checkUseFlg(rec.SMS_PAY_FLG),
      'STMT_CYCLE_CD': rec.STMT_CYCLE_CD,
      'APP_VAN1_CD': checkPg(rec.APP_VAN1_CD),
      'SM_MBS_CD': smMbsCd(rec.SM_MBS_CD),
      'MS_USE_FLG': checkUseFlg(rec.MS_USE_FLG),
      // 'USE_FLG': checkUseFlg(rec.USE_FLG),
      'REG_DNT': rec.REG_DNT,
    }
  })
  }else if(usrType == 3){
    headers = [
        ,"CO_NO"
        ,"CO_NM"
        ,"MID"
        ,"GID"
        ,"CAT_ID"
        ,"FEE"
        ,"MANUAL_PAY_FLG"
        ,"SMS_PAY_FLG"
        ,"STMT_CYCLE_CD"
        ,"APP_VAN1_CD"
        ,"SM_MBS_CD"
        ,"MS_USE_FLG"
        // ,"USE_FLG"
        ,"L3_VID" 
        ,"VGRP_NM3"
        ,"REG_DNT"                          
    ];

    headerName = {
        'CO_NO': '사업자번호',
        'CO_NM': '상호',
        'MID': 'MID',
        'GID': 'GID',
        'CAT_ID': '단말기번호',
        'FEE': '수수료',
        'MANUAL_PAY_FLG': '수기',
        'SMS_PAY_FLG': 'SMS',
        'STMT_CYCLE_CD': '정산주기',
        'APP_VAN1_CD': 'PG사',
        'SM_MBS_CD': '영중소 등급',
        'MS_USE_FLG': '영중소 사용여부',
        // 'USE_FLG': '사용여부',
        'L3_VID': '딜러',
        'VGRP_NM3': '딜러명',
        'REG_DNT': '등록일'
    }

  newRowData = data.map(rec => {
    return {
        'CO_NO': rec.CO_NO,
        'CO_NM': rec.CO_NM,
        'MID': rec.MID,
        'GID': rec.GID,
        'CAT_ID': rec.CAT_ID,
        'FEE': rec.FEE,
        'MANUAL_PAY_FLG': checkUseFlg(rec.MANUAL_PAY_FLG),
        'SMS_PAY_FLG': checkUseFlg(rec.SMS_PAY_FLG),
        'STMT_CYCLE_CD': rec.STMT_CYCLE_CD,
        'APP_VAN1_CD': checkPg(rec.APP_VAN1_CD),
        'SM_MBS_CD': smMbsCd(rec.SM_MBS_CD),
        'MS_USE_FLG': checkUseFlg(rec.MS_USE_FLG),
        // 'USE_FLG': rec.USE_FLG,
        'L3_VID': rec.L3_VID,
        'VGRP_NM3': rec.VGRP_NM3,
        'REG_DNT': rec.REG_DNT,
    }
  })
  }else if(usrType == 4){
    headers = [
        ,"CO_NO"
        ,"CO_NM"
        ,"MID"
        ,"GID"
        ,"CAT_ID"
        ,"FEE"
        ,"MANUAL_PAY_FLG"
        ,"SMS_PAY_FLG"
        ,"STMT_CYCLE_CD"
        ,"APP_VAN1_CD"
        ,"SM_MBS_CD"
        ,"MS_USE_FLG"
        // ,"USE_FLG"
        ,"L2_VID"
        ,"VGRP_NM2"
        ,"L3_VID" 
        ,"VGRP_NM3"
        ,"REG_DNT"                          
    ];

    headerName = {
        'CO_NO': '사업자번호',
        'CO_NM': '상호',
        'MID': 'MID',
        'GID': 'GID',
        'CAT_ID': '단말기번호',
        'FEE': '수수료',
        'MANUAL_PAY_FLG': '수기',
        'SMS_PAY_FLG': 'SMS',
        'STMT_CYCLE_CD': '정산주기',
        'APP_VAN1_CD': 'PG사',
        'SM_MBS_CD': '영중소 등급',
        'MS_USE_FLG': '영중소 사용여부',
        // 'USE_FLG': '사용여부',
        'L2_VID': '대리점',
        'VGRP_NM2': '대리점명',
        'L3_VID': '딜러',
        'VGRP_NM3': '딜러명',
        'REG_DNT': '등록일'
    }

  newRowData = data.map(rec => {
    return {
        'CO_NO': rec.CO_NO,
        'CO_NM': rec.CO_NM,
        'MID': rec.MID,
        'GID': rec.GID,
        'CAT_ID': rec.CAT_ID,
        'FEE': rec.FEE,
        'MANUAL_PAY_FLG': checkUseFlg(rec.MANUAL_PAY_FLG),
        'SMS_PAY_FLG': checkUseFlg(rec.SMS_PAY_FLG),
        'STMT_CYCLE_CD': rec.STMT_CYCLE_CD,
        'APP_VAN1_CD': checkPg(rec.APP_VAN1_CD),
        'SM_MBS_CD': smMbsCd(rec.SM_MBS_CD),
        'MS_USE_FLG': checkUseFlg(rec.MS_USE_FLG),
        // 'USE_FLG': checkUseFlg(rec.USE_FLG),
        'L2_VID': rec.L2_VID,
        'VGRP_NM2': rec.VGRP_NM2,
        'L3_VID': rec.L3_VID,
        'VGRP_NM3': rec.VGRP_NM3,
        'REG_DNT': rec.REG_DNT,
    }
  })
  }else{
    headers = [
        ,"CO_NO"
        ,"CO_NM"
        ,"MID"
        ,"GID"
        ,"CAT_ID"
        ,"FEE"
        ,"MANUAL_PAY_FLG"
        ,"SMS_PAY_FLG"
        ,"STMT_CYCLE_CD"
        ,"APP_VAN1_CD"
        ,"SM_MBS_CD"
        ,"MS_USE_FLG"
        // ,"USE_FLG"
        ,"L1_VID"
        ,"VGRP_NM"
        ,"L2_VID"
        ,"VGRP_NM2"
        ,"L3_VID" 
        ,"VGRP_NM3"
        ,"REG_DNT"                          
    ];

    headerName = {
        'CO_NO': '사업자번호',
        'CO_NM': '상호',
        'MID': 'MID',
        'GID': 'GID',
        'CAT_ID':'단말기번호',
        'FEE': '수수료',
        'MANUAL_PAY_FLG': '수기',
        'SMS_PAY_FLG': 'SMS',
        'STMT_CYCLE_CD': '정산주기',
        'APP_VAN1_CD': 'PG사',
        'SM_MBS_CD': '영중소 등급',
        'MS_USE_FLG': '영중소 사용여부',
        // 'USE_FLG': '사용여부',
        'L1_VID': '총판',
        'VGRP_NM': '총판명',
        'L2_VID': '대리점',
        'VGRP_NM2': '대리점명',
        'L3_VID': '딜러',
        'VGRP_NM3': '딜러명',
        'REG_DNT': '등록일'
    }

  newRowData = data.map(rec => {
    return {
        'CO_NO': rec.CO_NO,
        'CO_NM': rec.CO_NM,
        'MID': rec.MID,
        'GID': rec.GID,
        'CAT_ID': rec.CAT_ID,
        'FEE': rec.FEE,
        'MANUAL_PAY_FLG': checkUseFlg(rec.MANUAL_PAY_FLG),
        'SMS_PAY_FLG': checkUseFlg(rec.SMS_PAY_FLG),
        'STMT_CYCLE_CD': rec.STMT_CYCLE_CD,
        'APP_VAN1_CD': checkPg(rec.APP_VAN1_CD),
        'SM_MBS_CD': smMbsCd(rec.SM_MBS_CD),
        'MS_USE_FLG': checkUseFlg(rec.MS_USE_FLG),
        // 'USE_FLG': checkUseFlg(rec.USE_FLG),
        'L1_VID': rec.L1_VID,
        'VGRP_NM': rec.VGRP_NM,
        'L2_VID': rec.L2_VID,
        'VGRP_NM2': rec.VGRP_NM2,
        'L3_VID': rec.L3_VID,
        'VGRP_NM3': rec.VGRP_NM3,
        'REG_DNT': rec.REG_DNT,
    }
  })
  }
    newRowData.unshift(headerName);

    const book = xlsx.utils.book_new();
    const midInfotList = xlsx.utils.json_to_sheet( newRowData

, { header :headers , skipHeader : true } );

xlsx.utils.book_append_sheet( book,midInfotList);

xlsx.writeFile( book, '페이시스_가맹점정보.xlsx');
}

