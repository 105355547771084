import React, { useState , useContext, useEffect } from 'react';
import {
    Card,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from '@material-ui/core';
import GroupVidVidGidMidInfo from '../../../common/GroupVidVidGidMidInfo'
import Button from '@mui/material/Button';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { MyContext } from '../../../App';
import { useCookies } from 'react-cookie';
import { Navigate ,useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { CheckUseFlgGrid } from '../../../common/grid/CheckUseFlgGrid';
import { smMbsCdGrid } from '../../../common/grid/smMbsCdGrid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { FormControl } from '@material-ui/core';


const MidFee =() => {

   const { selectInfoList, setSelectInfoList } = useContext(MyContext);

    const [cookies, setCookie, removeCookie] = useCookies();
    const rowHeight = 50;
    const [rowData,setRowData] = useState();
    const navigate = useNavigate();
    //권한 show hide
    const [l1vidDisplay , setl1vidDisplay] = React.useState(false);
    const [l2vidDisplay , setl2vidDisplay] = React.useState(false);
    const [l3vidDisplay , setl3vidDisplay] = React.useState(false);
    const [selectType, setSelectType] = useState('select');
    const [selectTypeList,setSelectTypeList] =useState([{}]); 

     //권한에 따라 show hide 설정
     function styleDisplay(usrType){
      if(usrType == 4 ){
        setl1vidDisplay(true);
        setSelectTypeList(
          [{value : 'select' ,lavel : '선택하세요'}
          ,{value : 'cono' ,lavel : '사업자번호'}
          ,{value :'mid',lavel :'MID'}
          ,{value :'l2vid',lavel :'대리점'}
          ,{value :'l3vid',lavel :'딜러'}
          ,{value :'catId',lavel :'단말기번호'}
        ]);
      }else if( usrType == 3 ){
        setl1vidDisplay(true);
        setl2vidDisplay(true);
        setSelectTypeList(
          [{value : 'select' ,lavel : '선택하세요'}
          ,{value : 'cono' ,lavel : '사업자번호'}
          ,{value :'l3vid',lavel :'딜러'}
          ,{value :'mid',lavel :'MID'}
          ,{value :'catId',lavel :'단말기번호'}
        ]); 
      }else if( usrType == 1 ){
        setl1vidDisplay(true);
        setl2vidDisplay(true);
        setl3vidDisplay(true);
        setSelectTypeList(
          [{value : 'select' ,lavel : '선택하세요'}
          ,{value : 'cono' ,lavel : '사업자번호'}
          ,{value :'mid',lavel :'MID'}
          ,{value :'catId',lavel :'단말기번호'}
        ]); 
      }else{
        setSelectTypeList([{value : 'select' ,lavel : '선택하세요'}
        ,{value : 'cono' ,lavel : '사업자번호'}
        ,{value :'mid',lavel :'MID'}
        ,{value :'vid',lavel :'총판'}
        ,{value :'l2vid',lavel :'대리점'}
        ,{value :'l3vid',lavel :'딜러'}
        ,{value :'catId',lavel :'단말기번호'}
      ]); 
      }
  }     


    const midFeeList = () =>{
      axios
            .post(
               process.env.REACT_APP_API_HOST + "/mms/selectMidFeeList",
               
               {
                  token : cookies.token,
                  userId : cookies.userid,
                  usrTypeId : cookies.id,
                  usrType:cookies.type,
                  l1_vid : selectInfoList.l1Vid,
                  l2_vid : selectInfoList.l2Vid,
                  l3_vid : selectInfoList.l3Vid,
                  mid : selectInfoList.mid,
               },
               {
                  headers: {
                     'Content-Type': 'application/json',
                     'X-Requested-With': 'XMLHttpRequest',
                  },
               }
            )
            .then(function (response) {
               if (response.data.res_code != '0000') {
                 swal({
                   text: response.data.res_msg,
                   button: {
                     text: '확인',
                   },
                 });
                 if (response.data.res_code == '1004') {
                   swal({
                     text: response.data.res_msg,
                     button: {
                       text: '확인',
                     },
                   });
                   navigate('/login', { replace: false });
                 }
                 Swal.close();
               } else {
                  setRowData(response.data.data)
                 Swal.close();
               }
             }).catch(function (error) {
               Swal.close();
               swal({
                 text: '미지정 오류',
                 button: {
                   text: '확인',
                 },
               });
           });
           }

       useEffect(() => {
            styleDisplay(cookies.type);
      
       },[]);

    return (
        <form>
        <div style={{float: 'right'}}>
        <Button  size="small" variant="contained" color="primary" onClick={midFeeList}>
           조회
        </Button>
        </div>
        <br></br>
        <br></br>
        <GroupVidVidGidMidInfo/>
        <br></br>
         <Card>
        <FormControl  sx={{ m: 1, width: 250 }}>
            <Select size='small'
                defaultValue={'select'}
                value={selectTypeList.value}
                inputProps={{
                  id: 'selectDateType',
                }}
                onChange={(event) =>{
                  setSelectType(event.target.value);
                  }}
                  style={{marginLeft:'30px'}}
              >
                 {selectTypeList?.map(option => {
              return (
              <MenuItem key={option} value={option.value}>{option.lavel}</MenuItem>
                );
             })}
            </Select>
          </FormControl>
            <TextField  id="textSelectType"  style={{marginLeft:'30px'}} size="small" variant="outlined" />
            </Card>
            <br>
            </br>
        <Card>
        <CardHeader title="현재 적용 수수료" />
          <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
           <AgGridReact rowHeight={rowHeight}
           pagination={true}
           paginationPageSize={20}
               rowData={rowData}>
               <AgGridColumn  width={170} headerName = "MID" field="MID"></AgGridColumn>
               <AgGridColumn  width={170} headerName = "사업자번호" field="CO_NO"></AgGridColumn>
               <AgGridColumn  width={250} headerName = "상호" field="CO_NM"></AgGridColumn>
               <AgGridColumn hide={setl1vidDisplay} width={170} headerName = "총판" field="L1_VID"></AgGridColumn>
               <AgGridColumn hide={setl2vidDisplay} width={170} headerName = "대리점" field="L2_VID"></AgGridColumn>
               <AgGridColumn hide={setl3vidDisplay} width={170} headerName = "딜러" field="L3_VID"></AgGridColumn>
               <AgGridColumn  width={140} headerName = "수수료" field="FEE"></AgGridColumn>
               <AgGridColumn  width={150} headerName = "영중소 사용여부" field="MS_USE_FLG" cellRenderer={CheckUseFlgGrid}></AgGridColumn>
               <AgGridColumn  width={150} headerName = "영중소 등급" field="SM_MBS_CD" cellRenderer={smMbsCdGrid}></AgGridColumn>
           </AgGridReact>
       </div>
       </Card>

          </form>
    );

}

export default MidFee;