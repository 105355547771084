export function checkPg(x){
    switch(x){
        case '02' : return '올앳'
        case '03' : return '다날'
        case '04' : return '갤컴'
        case '05' : return 'KIS'
        case '06' : return '토스'
        case '07' : return 'KSNET'
        case '08' : return '섹타나인'
        case '01' : return '코페이'
        default : return '-'
      }
}