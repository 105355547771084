import React, { useState ,useContext ,useEffect} from 'react';
import {
    Card,
  } from '@material-ui/core';

  import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import GroupVidVidGidMidInfo from '../../../common/GroupVidVidGidMidInfo'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import { MyContext } from '../../../App';
import { deleteSpecialChar } from '../../../common/deleteSpecialChar';
import moment from 'moment';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { dateRange } from '../../../common/dateRange';
import { getFormatDate } from '../../../common/getFormatDate';
import { dateYYYYMMDDGrid } from '../../../common/grid/dateYYYYMMDDGrid';
import { numberWithCommasGrid } from '../../../common/grid/numberWithCommasGrid';
import BankReportDetailButton from './BankReportDetailButton';
import { Navigate ,useNavigate } from 'react-router-dom';
import { bankReportExcel } from '../../../common/excel/bankReportExcel';
import { ko } from 'date-fns/locale';

const BankReport =() => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const { selectInfoList, setSelectInfoList } = useContext(MyContext);
  //그리도 싸이즈
  const rowHeight = 35;
  //입금내역서
  const [rowData,setRowData] = useState();
  //입금내역서 합계
  const [rowDataTotal,setRowDataTotal] = useState();
  const currentDate = new Date();
  //시작일자 ,종료일자
  const [startDate, setStartDate] = React.useState(getFormatDate(currentDate));
  const [endDate, setEndDate] = React.useState(getFormatDate(currentDate));
  //입금일자,거래일자 
  const [dateList , setDateList] = React.useState(
    [{value : 'stmtDt' ,lavel : '입금일자'}]);

    const [selectTypeList,setSelectTypeList] =useState([{}]); 
  
    const [dateType, setDateType] = useState('stmtDt');
    const [selectType, setSelectType] = useState('select');

    
    //권한 show hide
    const [l1vidDisplay , setl1vidDisplay] = React.useState(false);
    const [l2vidDisplay , setl2vidDisplay] = React.useState(false);
    const [l3vidDisplay , setl3vidDisplay] = React.useState(false);

  //권한에 따라 show hide 설정
  function styleDisplay(usrType){
    if(usrType == 4 ){
      setl1vidDisplay(true);
      setSelectTypeList(
        [{value : 'select' ,lavel : '선택하세요'}
        ,{value : 'cono' ,lavel : '사업자번호'}
        ,{value :'l2vid',lavel :'대리점'}
        ,{value :'l3vid',lavel :'딜러'}
        ,{value :'l2vidNm',lavel :'대리점명'}
        ,{value :'l3vidNm',lavel :'딜러명'}
        ,{value :'mid',lavel :'MID'}
        ,{value :'gid',lavel :'GID'}
        ,{value :'catId',lavel :'단말기번호'}
      ]);
    }else if( usrType == 3 ){
      setl1vidDisplay(true);
      setl2vidDisplay(true);
      setSelectTypeList(
        [{value : 'select' ,lavel : '선택하세요'}
        ,{value : 'cono' ,lavel : '사업자번호'}
        ,{value :'l3vid',lavel :'딜러'}
        ,{value :'l3vidNm',lavel :'딜러명'}
        ,{value :'mid',lavel :'MID'}
        ,{value :'gid',lavel :'GID'}
        ,{value :'catId',lavel :'단말기번호'}
      ]); 
    }else if( usrType == 1 ){
      setl1vidDisplay(true);
      setl2vidDisplay(true);
      setl3vidDisplay(true);
      setSelectTypeList(
        [{value : 'select' ,lavel : '선택하세요'}
        ,{value : 'cono' ,lavel : '사업자번호'}
        ,{value :'mid',lavel :'MID'}
        ,{value :'gid',lavel :'GID'}
        ,{value :'catId',lavel :'단말기번호'}
      ]); 
    }else{
      setSelectTypeList([{value : 'select' ,lavel : '선택하세요'}
      ,{value : 'cono' ,lavel : '사업자번호'}
      ,{value :'vid',lavel :'총판'}
      ,{value :'l2vid',lavel :'대리점'}
      ,{value :'l3vid',lavel :'딜러'}
      ,{value :'vidNm',lavel :'총판명'}
      ,{value :'l2vidNm',lavel :'대리점명'}
      ,{value :'l3vidNm',lavel :'딜러명'}
      ,{value :'mid',lavel :'MID'}
      ,{value :'gid',lavel :'GID'}
      ,{value :'catId',lavel :'단말기번호'}
    ]); 
    }
}


    const bankListData = () => {
      let limitDay = dateRange(startDate,endDate);
      if(limitDay > 31) {
        swal({
          text: '시작일자와(과) 종료일자의 간격은 31일 이내여야 합니다.' ,
          button: {
            text: '확인',
          },
        });
        return false;
      }
      Swal.showLoading();
      axios
            .post(
               process.env.REACT_APP_API_HOST + "/mms/bankListData",
               {
                  token : cookies.token,
                  userId : cookies.userid,
                  usrTypeId : cookies.id,
                  usrType:cookies.type,
                  l1_vid : selectInfoList.l1Vid,
                  l2_vid : selectInfoList.l2Vid,
                  l3_vid : selectInfoList.l3Vid,
                  mid : selectInfoList.mid,
                  startDate : deleteSpecialChar(startDate),
                  endDate : deleteSpecialChar(endDate) ,
                  dateType : dateType ,
                  selectType : selectType,
                  textSelectType : document.getElementById('textSelectType').value
               },
               {
                  headers: {
                     'Content-Type': 'application/json',
                     'X-Requested-With': 'XMLHttpRequest',
                  },
               }
            )
            .then(function (response) {
              if (response.data.res_code != '0000') {
                swal({
                  text: response.data.res_msg,
                  button: {
                    text: '확인',
                  },
                });
                if (response.data.res_code == '1004') {
                  swal({
                    text: response.data.res_msg,
                    button: {
                      text: '확인',
                    },
                  });
                  navigate('/login', { replace: false });
                }
                Swal.close();
              } else {
		            setRowData(response.data.data)
                setRowDataTotal(response.data.total)
                Swal.close();
              }
            }).catch(function (error) {
              Swal.close();
              swal({
                text: '미지정 오류',
                button: {
                  text: '확인',
                },
              });
            });
    }
    //날짜 버튼
    const dateButton = (e) => {
      const date = new Date();
      const dates = new Date();
      if(e.target.value == '0'){
        date.setDate(date.getDate() - 1);
        setStartDate(getFormatDate(date));
        setEndDate(getFormatDate(date));
      }else if(e.target.value == '1'){
        date.setDate(date.getDate());
        setStartDate(getFormatDate(date));
        setEndDate(getFormatDate(date));
      }else if(e.target.value == '2'){
        date.setDate(date.getDate() - 7);
        dates.setDate(dates.getDate());
        setStartDate(getFormatDate(date));
        setEndDate(getFormatDate(dates));
      }else if(e.target.value == '3'){
        date.setDate(date.getDate() - 31);
        dates.setDate(dates.getDate());
        setStartDate(getFormatDate(date));
        setEndDate(getFormatDate(dates));
      }
    }

    function excelDownLoad(){
      bankReportExcel(rowData,cookies.type);
    }

    useEffect(() => {
      styleDisplay(cookies.type);
    },[]);

    return (
        <form>
        <div style={{float: 'right'}}>
        <Button  size="small" variant="contained" color="primary" onClick={bankListData}>
           조회
        </Button>
        <Button  size="small" variant="contained" color="success" onClick={excelDownLoad}>
           EXCEL
        </Button>
        </div>
        <br></br>
        <br></br>
        <GroupVidVidGidMidInfo/>
        <br></br>
        <Card>
        <FormControl  sx={{ m: 1, width: 250 }}>
          <Select size='small'
                defaultValue={'stmtDt'}
                value={dateList.value}
                inputProps={{
                  id: 'selectDateType',
                }}
                onChange={(event) =>{
                  setDateType(event.target.value);
                  }}
                style={{marginRight:'15px'}}
              >
                 {dateList?.map(option => {
              return (
              <MenuItem key={option} value={option.value}>
                  {option.lavel}
              </MenuItem>
                );
             })}
            </Select>
            </FormControl>
           
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
            <DatePicker
              value={startDate}
              inputFormat={"yyyy/MM/dd"}
              mask={"____/__/__"}
              onChange={(newValue) => {
                setStartDate(getFormatDate(newValue));
              }}
              inputVariant="outlined"
              renderInput={(params) => <TextField  style={{width:'150px'}} size='small'{...params} />}
            />
          </LocalizationProvider>
           ~ 
           <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
            <DatePicker
              value={endDate}
              inputFormat={"yyyy/MM/dd"}
              mask={"____/__/__"}
              onChange={(newValue) => {
                setEndDate(getFormatDate(newValue));
              }}
              renderInput={(params) => <TextField  
                
                style={{width:'150px'}} size='small'{...params} />}
            />
          </LocalizationProvider>

          <Button size="medium" style={{marginLeft:'10px'}} value="0" onClick={dateButton} variant="outlined" color="primary">어제</Button>
          <Button size="medium" style={{marginLeft:'10px'}} value="1" onClick={dateButton} variant="outlined" color="primary">오늘</Button>
          <Button size="medium" style={{marginLeft:'10px'}} value="2" onClick={dateButton} variant="outlined" color="primary">일주일</Button>
          <Button size="medium" style={{marginLeft:'10px'}} value="3" onClick={dateButton} variant="outlined" color="primary">1개월</Button>

            {/* 선택하세요 */}
          <FormControl  sx={{ m: 1, width: 250 }}>
          <Select size='small'
                defaultValue={'select'}
                value={selectTypeList.value}
                inputProps={{
                  id: 'selectDateType',
                }}
                onChange={(event) =>{
                  setSelectType(event.target.value);
                  }}
                  style={{marginLeft:'30px'}}
              >
                 {selectTypeList?.map(option => {
              return (
              <MenuItem key={option} value={option.value}>
                  {option.lavel}
              </MenuItem>
                );
             })}
            </Select>
            </FormControl>
            <TextField  id="textSelectType"  style={{marginLeft:'30px'}} size="small" variant="outlined" />
        </Card>

        
        
        <br></br>
        <Card style={l3vidDisplay  ? {display:'none'} : {display: 'default'}}>
        <div className="ag-theme-alpine" style={{height: 240, width: '100%' }}>
           <AgGridReact rowHeight={rowHeight}
             frameworkComponents={{
            }}
            defaultColDef={{ resizable: true }}
            debounceVerticalScrollbar={true}
           pagination={true}
           enableCellTextSelection={true}
           paginationPageSize={4}
               rowData={rowDataTotal}>
               <AgGridColumn  width={120} headerName = "입금일자" field="STMT_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
               <AgGridColumn  width={150} headerName = "승인금액" field="APP_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={90}  headerName = "승인건수" field="APP_CNT"></AgGridColumn>
               <AgGridColumn  width={150} headerName = "취소금액" field="CC_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={90} headerName = "취소건수" field="CC_CNT"></AgGridColumn>
               <AgGridColumn  width={120} headerName = "수수료" field="FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={120} headerName = "VAT" field="VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={120} headerName = "선지급 수수료" field="PRESTMT_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={120} headerName = "선지급 금액" field="PRESTMT_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={150} headerName = "입금액" field="DPST_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
           </AgGridReact>
            </div>
          </Card>
          <br></br>
        <Card>
        <div className="ag-theme-alpine" style={{height: 470, width: '100%'}}>
           <AgGridReact rowHeight={rowHeight}
             frameworkComponents={{
              bankReportDetailButton : BankReportDetailButton,
            }}
            defaultColDef={{ resizable: true }}
            debounceVerticalScrollbar={true}
           pagination={true}
           enableCellTextSelection={true}
           paginationPageSize={10}
               rowData={rowData}>
               <AgGridColumn  width={120} headerName = "입금일자" field="STMT_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
               <AgGridColumn  width={200} headerName = "상호명" field="CO_NM"></AgGridColumn>
               <AgGridColumn  width={130} headerName = "MID" field="MID"></AgGridColumn>
               <AgGridColumn  width={130} headerName = "GID" field="GID"></AgGridColumn>
               <AgGridColumn  width={140} headerName = "단말기번호" field="CAT_ID"></AgGridColumn>
               <AgGridColumn hide={l1vidDisplay}  width={140} headerName = "총판" field="VID"></AgGridColumn>
               <AgGridColumn hide={l1vidDisplay}  width={140} headerName = "총판명" field="VGRP_NM"></AgGridColumn>
               <AgGridColumn hide={l2vidDisplay}  width={140} headerName = "대리점" field="L2_VID"></AgGridColumn>
               <AgGridColumn hide={l2vidDisplay}  width={140} headerName = "대리점명" field="VGRP_NM2"></AgGridColumn>
               <AgGridColumn hide={l3vidDisplay}  width={140} headerName = "딜러" field="L3_VID"></AgGridColumn>
               <AgGridColumn hide={l3vidDisplay}  width={140} headerName = "딜러명" field="VGRP_NM3"></AgGridColumn>
               <AgGridColumn  width={150} headerName = "승인금액" field="APP_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={90}  headerName = "승인건수" field="APP_CNT"></AgGridColumn>
               <AgGridColumn  width={150} headerName = "취소금액" field="CC_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={90} headerName = "취소건수" field="CC_CNT"></AgGridColumn>
               <AgGridColumn  width={120} headerName = "수수료" field="FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={120} headerName = "VAT" field="VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={120} headerName = "선지급 수수료" field="PRESTMT_FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={120} headerName = "선지급 금액" field="PRESTMT_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={150} headerName = "입금액" field="DPST_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={150} headerName = "상세보기"   cellRenderer = "bankReportDetailButton"></AgGridColumn>
           </AgGridReact>
            </div>
          </Card>
          <br></br>
          <br></br>
       
          </form>
    );

}

export default BankReport;