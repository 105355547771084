import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Table,
    TableCell,
    TableRow
  } from '@material-ui/core';

export default (props) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return (
    <span>
      <span>{cellValue}</span>&nbsp;
      <Button onClick={handleClickOpen} >상세보기</Button>
      <Dialog
        open={open}
        keepMounted
        maxWidth={false}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"결제 정보"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <Table size='small' style={{marginBottom:'60px'}}>
                    <TableRow>
                        <TableCell>
                            취소유형
                        </TableCell>
                        <TableCell>
                            전체취소
                        </TableCell>
                        <TableCell>
                            금액
                        </TableCell>
                        <TableCell>
                            84,000
                        </TableCell>
                        <TableCell>
                            취소금액
                        </TableCell>
                        <TableCell>
                            84000
                        </TableCell>
                        <TableCell>
                            취소 사유
                        </TableCell>
                        <TableCell>
                            대리점 요청
                        </TableCell>
                        <TableCell>
                            취소 비밀번호
                        </TableCell>
                        <TableCell>
                            *******
                        </TableCell>
                        <TableCell>
                            <Button variant='outlined'>취소</Button>
                        </TableCell>
                    </TableRow>
                </Table>

                <Table size='small' style={{marginBottom:'60px'}}>
                    <TableRow>
                        <TableCell>
                            결제수단
                        </TableCell>
                        <TableCell>
                            신용카드
                        </TableCell>
                        <TableCell>
                            상 호
                        </TableCell>
                        <TableCell>
                            락휴노래타운 인계점
                        </TableCell>
                        <TableCell>
                            MID
                        </TableCell>
                        <TableCell>
                            pand32268m
                        </TableCell>
                        <TableCell>
                            GID
                        </TableCell>
                        <TableCell>
                            pand95000g
                        </TableCell>
                        <TableCell>
                            VID
                        </TableCell>
                        <TableCell>
                            pand84300a
                        </TableCell>
                        <TableCell>
                            접속구분
                        </TableCell>
                        <TableCell>
                            Off-Line
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            총판ID
                        </TableCell>
                        <TableCell>
                            pand84300a
                        </TableCell>
                        <TableCell>
                            총판명
                        </TableCell>
                        <TableCell>
                            페이엔플러스(일반)
                        </TableCell>
                        <TableCell>
                            대리점ID
                        </TableCell>
                        <TableCell>
                            pand32268m
                        </TableCell>
                        <TableCell>
                            대리점ID
                        </TableCell>
                        <TableCell>
                            페이엔플러스 일반
                        </TableCell>
                        <TableCell>
                            딜러ID
                        </TableCell>
                        <TableCell>
                            pand12312a
                        </TableCell>
                        <TableCell>
                            딜러명
                        </TableCell>
                        <TableCell>
                            페이엔플러스일반
                        </TableCell>
                    </TableRow>
                </Table>

                <Table size='small' style={{marginBottom:'20px'}}>
                    <TableRow>
                        <TableCell>
                            승인일시
                        </TableCell>
                        <TableCell>
                        2021/12/11 23:59:55
                        </TableCell>
                        <TableCell>
                            승인매입
                        </TableCell>
                        <TableCell>
                             2021/12/13
                        </TableCell>
                        <TableCell>
                        승인지급
                        </TableCell>
                        <TableCell>
                        2021/12/13
                        </TableCell>
                        <TableCell>
                        승인VAN
                        </TableCell>
                        <TableCell>
                        올앳
                        </TableCell>
                        <TableCell>
                        취소일시
                        </TableCell>
                        <TableCell>
                            -
                        </TableCell>
                        <TableCell>
                        취소매입
                        </TableCell>
                        <TableCell>
                           -
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                          취소지급
                        </TableCell>
                        <TableCell>
                           -
                        </TableCell>
                        <TableCell>
                           카드사
                        </TableCell>
                        <TableCell>
                            신한
                        </TableCell>
                        <TableCell>
                            가맹점번호
                        </TableCell>
                        <TableCell>
                        85948073
                        </TableCell>
                        <TableCell>
                            카드번호
                        </TableCell>
                        <TableCell>
                        451842**********
                        </TableCell>
                        <TableCell>
                        승인번호
                        </TableCell>
                        <TableCell>
                        19072247
                        </TableCell>
                        <TableCell>
                        고객ID
                        </TableCell>
                        <TableCell>
                        -
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                        상품명
                        </TableCell>
                        <TableCell>
                        1046499537
                        </TableCell>
                        <TableCell>
                        결제금액
                        </TableCell>
                        <TableCell>
                        45,800
                        </TableCell>
                        <TableCell>
                        할 부
                        </TableCell>
                        <TableCell>
                        00개월
                        </TableCell>
                        <TableCell>
                        카드구분
                        </TableCell>
                        <TableCell>
                        일반
                        </TableCell>
                        <TableCell>
                        청구자휴대폰
                        </TableCell>
                        <TableCell>
                      -
                        </TableCell>
                        <TableCell>
                        청구일
                        </TableCell>
                        <TableCell>
                        -
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                        T I D
                        </TableCell>
                        <TableCell>
                        rawo40272m01032112120000020362
                        </TableCell>
                        <TableCell>
                        상 태
                        </TableCell>
                        <TableCell>
                        승인
                        </TableCell>
                        <TableCell>
                        주문번호
                        </TableCell>
                        <TableCell>
                        20211211235955068239
                        </TableCell>
                        <TableCell>
                        취소작업자
                        </TableCell>
                        <TableCell>
                        -
                        </TableCell>
                        <TableCell>
                        취소사유
                        </TableCell>
                        <TableCell>
                      -
                        </TableCell>
                        <TableCell>
                        -
                        </TableCell>
                        <TableCell>
                        -
                        </TableCell>
                    </TableRow>
                </Table>


          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};