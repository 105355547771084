import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import swal from "sweetalert";
import { useCookies } from "react-cookie";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Popup() {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [data, setData] = useState([]);
    const [dayPopup, setDayPopup] = useState({});
    const [modalOpen, setModalOpen] = useState([]);

    const handleChange = (event, seq) => {
        const { checked } = event.target;
        setDayPopup((prevDayPopup) => ({
            ...prevDayPopup,
            [seq]: checked,
        }));
    };

    const getPopupList = () => {
        Swal.showLoading();
        axios
            .post(
                process.env.REACT_APP_API_HOST + "/mms/noticeList",
                {
                    token: cookies.token,
                    userId: cookies.userid,
                    type: cookies.type,
                    oper: "popupList",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                    },
                }
            )
            .then(function (response) {
                if (response.data.res_code !== "0000") {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: "확인",
                        },
                    });
                    if (response.data.res_code === "1004") {
                        swal({
                            text: response.data.res_msg,
                            button: {
                                text: "확인",
                            },
                        });
                        navigate("/login", { replace: false });
                    }
                    Swal.close();
                } else {
                    getPopup(response.data.data[0]);
                    Swal.close();
                }
            })
            .catch(function (error) {
                console.error(error);
                Swal.close();
                swal({
                    text: "미지정 오류",
                    button: {
                        text: "확인",
                    },
                });
            });
    };

    const getPopup = (result) => {
        Swal.showLoading();

        const seqList = result
            .map((cookie) => {
                if(!cookies[cookie.SEQ]){
                    return cookie.SEQ;
                } else {
                    return null;
                }
            })
            .filter((item) => item !== null);

        axios
            .post(
                process.env.REACT_APP_API_HOST + "/mms/noticeList",
                {
                    token: cookies.token,
                    userId: cookies.userid,
                    type: cookies.type,
                    seqList: seqList,
                    oper: "popup",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                    },
                }
            )
            .then(function (response) {
                if (response.data.res_code !== "0000") {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: "확인",
                        },
                    });
                    if (response.data.res_code === "1004") {
                        swal({
                            text: response.data.res_msg,
                            button: {
                                text: "확인",
                            },
                        });
                        navigate("/login", { replace: false });
                    }
                    Swal.close();
                } else {
                    setData(response.data.data[0]);
                    setModalOpen(response.data.data[0].map(() => true));
                    Swal.close();
                }
            })
            .catch(function (error) {
                console.error(error);
                Swal.close();
                swal({
                    text: "미지정 오류",
                    button: {
                        text: "확인",
                    },
                });
            });
    };

    const closePopup = (index) => {
        const modal = data[index];
        if (dayPopup[modal.SEQ]) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 1);
            expirationDate.setHours(0, 0, 0, 0)
            expirationDate.setTime(expirationDate.getTime() + (9 * 60 * 60 * 1000));
            setCookie(modal.SEQ, "true", { path:'/', secure:false, sameSite: 'lax', expires: expirationDate });
        }
        setModalOpen((prevModalOpen) =>
            prevModalOpen.map((open,i) => (i === index ? false : open))
        );
    };

    useEffect(() => {
        getPopupList();
    }, []);

    return (
        <>
            {data.map((modal, index) => (
                <Modal
                    key={modal.SEQ}
                    open={modalOpen[index]}
                    onClose={() => closePopup(index)}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "70%",
                            maxHeight: "80%",
                            overflowY: "auto",
                            backgroundColor: "#fff",
                            padding: "20px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            borderRadius: "4px",
                        }}
                    >
                        <div
                            style={{
                                borderBottom: "2px solid #eee",
                                marginBottom: "15px",
                                paddingBottom: "10px",
                            }}
                        >
                            <Typography variant="h4" style={{ fontWeight: "700" }}>
                                공지사항
                            </Typography>
                        </div>
                        <div
                            style={{
                                border: "1px solid #eee",
                                borderRadius: "4px",
                                backgroundColor: "#eee",
                                padding: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="h6" style={{ fontWeight: "700" }}>
                                {modal.TITLE}
                            </Typography>
                            <Typography variant="subtitle1" style={{ color: "#777" }}>
                                {modal.REG_DT}
                            </Typography>
                        </div>
                        <div style={{ padding: "10px" }}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `<style>
                  table {
                    border-collapse: collapse;
                  }
                  th, td {
                    border: 1px solid black;
                    padding: 8px;
                  }
                  </style>`,
                                }}
                            />
                            <div dangerouslySetInnerHTML={{ __html: modal.BODY }} />
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <span>
                                <input
                                type="checkbox"
                                checked={dayPopup[modal.SEQ] || false}
                                onChange={(event) => handleChange(event, modal.SEQ)}
                                />오늘 하루 팝업 보이지 않음
                            </span>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => closePopup(index)}
                            >
                                닫기
                            </Button>
                        </div>
                    </Box>
                </Modal>
            ))}
        </>
    );
}

export default Popup;
