import React, { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import swal from "sweetalert";
import Swal from "sweetalert2";
import axios from "axios";
import { Cookies } from "react-cookie";
import { useCookies } from 'react-cookie';
import { Navigate ,useNavigate } from 'react-router-dom';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { numberWithCommasGrid } from "../../../common/grid/numberWithCommasGrid";
import { dateYYYYMMDDGrid } from "../../../common/grid/dateYYYYMMDDGrid";
import { transStatusGrid } from "../../../common/grid/transStatusGrid";
import { Background } from "react-flow-renderer";
import { padding } from "@mui/system";
import {agencyReportDetailExcel} from "../../../common/excel/agencyReportDetailExcel";
import {paymentTypeGrid} from "../../../common/grid/paymentTypeGrid";

export default (props) =>{

    const rowHeight ='40';
    const [cookies, setCookie, removeCookie] = useCookies();
    const navigate = useNavigate();
    //입금보고서 상세내역
    const [rowData,setRowData] = useState();

    const [open,setOpen] = useState(false);

    const handleClickOpen = () =>{
        agencyReportDetail()
        setOpen(true);
    };

    const handleClose = () =>{
        setOpen(false);
    };

    //엑셀 다운로드
    function excelDownLoad() {
      agencyReportDetailExcel(rowData);
    }


    const agencyReportDetail = () =>{
        Swal.showLoading();
        axios
        .post(
           process.env.REACT_APP_API_HOST + "/mms/selectAgencyReportDetail",
           {
              token : cookies.token,
              userId : cookies.userid,
              usrTypeId : cookies.id,
              usrType:cookies.type,
              mid : props.data.MID,
              trDt : props.data.TR_DT
           },
           {
              headers: {
                 'Content-Type': 'application/json',
                 'X-Requested-With': 'XMLHttpRequest',
              },
           }
        )
        .then(function (response) {
          if (response.data.res_code != '0000') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            if (response.data.res_code == '1004') {
              swal({
                text: response.data.res_msg,
                button: {
                  text: '확인',
                },
              });
              navigate('/login', { replace: false });
            }
            Swal.close();
          } else {
            setRowData(response.data.data)
            Swal.close();
          }
        }).catch(function (error) {
          Swal.close();
          swal({
            text: '미지정 오류',
            button: {
              text: '확인',
            },
          });
        });
    }
    

    return (
        <span>
            <Button onClick={handleClickOpen}>상세보기</Button>
            <Dialog
                open={open}
                keepMounted
                maxWidth={false}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"상세내역"}</DialogTitle>
                
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                <div className="ag-theme-alpine" style={{height: 600, width: 1300}}>
                <div align="right" style={{paddingBottom : '10px'}}><Button align="right" size="small" variant="contained" color="success" onClick={excelDownLoad}>EXCEL</Button></div>
                  <AgGridReact rowHeight={rowHeight}
                      pagination={true}
                      paginationPageSize={20}
                      rowData={rowData}
                      enableCellTextSelection={true}
                       defaultColDef={{ resizable: true }}
                       debounceVerticalScrollbar={true}
                      getRowStyle={(params) => {
                        if (params.data.TRX_ST_CD >0) {
                          return { background: '#ff8a80'};
                        }
                      }
                    }
                            >
                      <AgGridColumn  width={270} headerName = "TID" field="TID"></AgGridColumn>
                      <AgGridColumn  width={120} headerName = "거래일자" field="TR_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
                      <AgGridColumn  width={120} headerName = "승인일자" field="APP_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
                      <AgGridColumn  width={120} headerName = "상점명" field="CO_NM"></AgGridColumn>
                      <AgGridColumn  width={130} headerName = "MID" field="MID"></AgGridColumn>
                      <AgGridColumn  width={130} headerName = "GID" field="GID"></AgGridColumn>
                      <AgGridColumn  width={80}  headerName = "상태" field="TRX_ST_CD" cellRenderer={transStatusGrid}></AgGridColumn>
                      <AgGridColumn  width={100} headerName = "수수료율" field="MID_FEE_PER"></AgGridColumn>
                      <AgGridColumn  width={120} headerName = "기준수수료율" field="VID_FEE_PER"></AgGridColumn>
                      <AgGridColumn  width={150} headerName = "승인금액" field="TR_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
                      <AgGridColumn  width={120} headerName = "수수료" field="FEE" cellRenderer={numberWithCommasGrid}></AgGridColumn>
                      <AgGridColumn  width={120} headerName = "VAT" field="VAT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
                      <AgGridColumn  width={150} headerName = "입금액" field="DPST_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
                  </AgGridReact>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>닫기</Button>
            </DialogActions>
        </Dialog>
      </span>
    );
};