import Swal from "sweetalert2";
import { checkPg } from "../checkPg";
import { numberWithCommas } from "../numberWithCommas";
import { transStatus } from "../transStatus";
export const transExcel = (data,usrType) =>{
  var headers;
  var headerName;
  var newRowData;
  const xlsx = require( "xlsx" );

  if(usrType == 1){
        headers = [
          "APP_DT"                               
          ,"APP_TM"                               
          ,"CC_DT"                               
          ,"CC_TM"                               
          ,"VAN_CD"                               
          ,"MID"
          ,"GID"
          ,"CO_NM"                               
          ,"GOODS_NM"                               
          ,"GOODS_AMT"                               
          ,"CARD_NO"                               
          ,"QUOTA_MON"                               
          ,"ORD_NO"                               
          ,"APP_NO"                               
          ,"ACQ_CARD_NM"                               
          ,"TRX_ST_CD"                               
          ,"TID"                               
          ,"ORD_NM"                               
          ,"ORD_HP"                               
      ];

      headerName = {
        'APP_DT': '결제일자',
        'APP_TM': '결제시간',
        'CC_DT': '취소일자',
        'CC_TM': '취소시간',
        'VAN_CD': 'PG',
        'MID': 'MID',
        'GID': 'GID',
        'CO_NM': '상점명',
        'GOODS_NM': '상품명',
        'GOODS_AMT': '결제금액',
        'CARD_NO': '카드번호',
        'QUOTA_MON': '할부',
        'ORD_NO': '주문번호',
        'APP_NO': '승인번호',
        'ACQ_CARD_NM': '매입사',
        'TRX_ST_CD': '거래상태',
        'TID': '거래번호',
        'ORD_NM': '구매자',
        'ORD_HP': '휴대폰'
      }


    newRowData = data.map(rec => {
      return {
        'APP_DT': rec.APP_DT,
        'APP_TM': rec.APP_TM,
        'CC_DT': rec.CC_DT,
        'CC_TM': rec.CC_TM,
        'VAN_CD': checkPg(rec.VAN_CD),
        'MID': rec.MID,
        'GID': rec.GID,
        'CO_NM': rec.CO_NM,
        'GOODS_NM': rec.GOODS_NM,
        'GOODS_AMT': rec.GOODS_AMT,
        'CARD_NO': rec.CARD_NO,
        'QUOTA_MON': rec.QUOTA_MON,
        'ORD_NO': rec.ORD_NO,
        'APP_NO': rec.APP_NO,
        'ACQ_CARD_NM': rec.ACQ_CARD_NM,
        'TRX_ST_CD': transStatus(rec.TRX_ST_CD),
        'TID': rec.TID,
        'ORD_NM': rec.ORD_NM,
        'ORD_HP': rec.ORD_HP
      }
    })
  }else if(usrType == 3){
          headers = [
            "APP_DT"                               
            ,"APP_TM"                               
            ,"CC_DT"                               
            ,"CC_TM"                               
            ,"VAN_CD"                               
            ,"MID"              
            ,"GID"                 
            ,"CO_NM"                               
            ,"GOODS_NM"                               
            ,"GOODS_AMT"                               
            ,"CARD_NO"                               
            ,"QUOTA_MON"                               
            ,"ORD_NO"                               
            ,"APP_NO"                               
            ,"ACQ_CARD_NM"                               
            ,"TRX_ST_CD"                               
            ,"L3_VID"
            ,"VGRP_NM3"
            ,"TID"                               
            ,"ORD_NM"                               
            ,"ORD_HP"                               
        ];

        headerName = {
          'APP_DT': '결제일자',
          'APP_TM': '결제시간',
          'CC_DT': '취소일자',
          'CC_TM': '취소시간',
          'VAN_CD': 'PG',
          'MID': 'MID',
          'GID': 'GID',
          'CO_NM': '상점명',
          'GOODS_NM': '상품명',
          'GOODS_AMT': '결제금액',
          'CARD_NO': '카드번호',
          'QUOTA_MON': '할부',
          'ORD_NO': '주문번호',
          'APP_NO': '승인번호',
          'ACQ_CARD_NM': '매입사',
          'TRX_ST_CD': '거래상태',
          'L3_VID': 'L3_VID',
          'VGRP_NM3': '딜러명',
          'TID': '거래번호',
          'ORD_NM': '구매자',
          'ORD_HP': '휴대폰'
        }


      newRowData = data.map(rec => {
        return {
          'APP_DT': rec.APP_DT,
          'APP_TM': rec.APP_TM,
          'CC_DT': rec.CC_DT,
          'CC_TM': rec.CC_TM,
          'VAN_CD': checkPg(rec.VAN_CD),
          'MID': rec.MID,
          'GID': rec.GID,
          'CO_NM': rec.CO_NM,
          'GOODS_NM': rec.GOODS_NM,
          'GOODS_AMT': rec.GOODS_AMT,
          'CARD_NO': rec.CARD_NO,
          'QUOTA_MON': rec.QUOTA_MON,
          'ORD_NO': rec.ORD_NO,
          'APP_NO': rec.APP_NO,
          'ACQ_CARD_NM': rec.ACQ_CARD_NM,
          'TRX_ST_CD': transStatus(rec.TRX_ST_CD),
          'L3_VID': rec.L3_VID,
          'VGRP_NM3': rec.VGRP_NM3,
          'TID': rec.TID,
          'ORD_NM': rec.ORD_NM,
          'ORD_HP': rec.ORD_HP
        }
      })
  }else if(usrType == 4){
          headers = [
            "APP_DT"                               
            ,"APP_TM"                               
            ,"CC_DT"                               
            ,"CC_TM"                               
            ,"VAN_CD"                               
            ,"MID"
            ,"GID"                               
            ,"CO_NM"                               
            ,"GOODS_NM"                               
            ,"GOODS_AMT"                               
            ,"CARD_NO"                               
            ,"QUOTA_MON"                               
            ,"ORD_NO"                               
            ,"APP_NO"                               
            ,"ACQ_CARD_NM"                               
            ,"TRX_ST_CD"                               
            ,"L2_VID"                               
            ,"L3_VID"
            ,"VGRP_NM2"
            ,"VGRP_NM3"
            ,"TID"                               
            ,"ORD_NM"                               
            ,"ORD_HP"                               
        ];

        headerName = {
          'APP_DT': '결제일자',
          'APP_TM': '결제시간',
          'CC_DT': '취소일자',
          'CC_TM': '취소시간',
          'VAN_CD': 'PG',
          'MID': 'MID',
          'GID': 'GID',
          'CO_NM': '상점명',
          'GOODS_NM': '상품명',
          'GOODS_AMT': '결제금액',
          'CARD_NO': '카드번호',
          'QUOTA_MON': '할부',
          'ORD_NO': '주문번호',
          'APP_NO': '승인번호',
          'ACQ_CARD_NM': '매입사',
          'TRX_ST_CD': '거래상태',
          'L2_VID': 'L2_VID',
          'L3_VID': 'L3_VID',
          'VGRP_NM2': '대리점명',
          'VGRP_NM3': '딜러명',
          'TID': '거래번호',
          'ORD_NM': '구매자',
          'ORD_HP': '휴대폰'
        }


      newRowData = data.map(rec => {
        return {
          'APP_DT': rec.APP_DT,
          'APP_TM': rec.APP_TM,
          'CC_DT': rec.CC_DT,
          'CC_TM': rec.CC_TM,
          'VAN_CD': checkPg(rec.VAN_CD),
          'MID': rec.MID,
          'GID': rec.GID,
          'CO_NM': rec.CO_NM,
          'GOODS_NM': rec.GOODS_NM,
          'GOODS_AMT': rec.GOODS_AMT,
          'CARD_NO': rec.CARD_NO,
          'QUOTA_MON': rec.QUOTA_MON,
          'ORD_NO': rec.ORD_NO,
          'APP_NO': rec.APP_NO,
          'ACQ_CARD_NM': rec.ACQ_CARD_NM,
          'TRX_ST_CD': transStatus(rec.TRX_ST_CD),
          'L2_VID': rec.L2_VID,
          'L3_VID': rec.L3_VID,
          'VGRP_NM2': rec.VGRP_NM2,
          'VGRP_NM3': rec.VGRP_NM3,
          'TID': rec.TID,
          'ORD_NM': rec.ORD_NM,
          'ORD_HP': rec.ORD_HP
        }
      })
  }else{
    headers = [
      "APP_DT"                               
      ,"APP_TM"                               
      ,"CC_DT"                               
      ,"CC_TM"                               
      ,"VAN_CD"                               
      ,"MID"
      ,"GID"                               
      ,"CO_NM"                               
      ,"GOODS_NM"                               
      ,"GOODS_AMT"                               
      ,"CARD_NO"                               
      ,"QUOTA_MON"                               
      ,"ORD_NO"                               
      ,"APP_NO"                               
      ,"ACQ_CARD_NM"                               
      ,"TRX_ST_CD"                               
      ,"L1_VID"                               
      ,"L2_VID"                               
      ,"L3_VID"
      ,"VGRP_NM"
      ,"VGRP_NM2"
      ,"VGRP_NM3"
      ,"TID"                               
      ,"ORD_NM"                               
      ,"ORD_HP"                               
  ];

  headerName = {
    'APP_DT': '결제일자',
    'APP_TM': '결제시간',
    'CC_DT': '취소일자',
    'CC_TM': '취소시간',
    'VAN_CD': 'PG',
    'MID': 'MID',
    'GID': 'GID',
    'CO_NM': '상점명',
    'GOODS_NM': '상품명',
    'GOODS_AMT': '결제금액',
    'CARD_NO': '카드번호',
    'QUOTA_MON': '할부',
    'ORD_NO': '주문번호',
    'APP_NO': '승인번호',
    'ACQ_CARD_NM': '매입사',
    'TRX_ST_CD': '거래상태',
    'L1_VID': 'L1_VID',
    'L2_VID': 'L2_VID',
    'L3_VID': 'L3_VID',
    'VGRP_NM': '총판명',
    'VGRP_NM2': '대리점명',
    'VGRP_NM3': '딜러명',
    'TID': '거래번호',
    'ORD_NM': '구매자',
    'ORD_HP': '휴대폰'
  }


newRowData = data.map(rec => {
  return {
    'APP_DT': rec.APP_DT,
    'APP_TM': rec.APP_TM,
    'CC_DT': rec.CC_DT,
    'CC_TM': rec.CC_TM,
    'VAN_CD': checkPg(rec.VAN_CD),
    'MID': rec.MID,
    'GID': rec.GID,
    'CO_NM': rec.CO_NM,
    'GOODS_NM': rec.GOODS_NM,
    'GOODS_AMT': rec.GOODS_AMT,
    'CARD_NO': rec.CARD_NO,
    'QUOTA_MON': rec.QUOTA_MON,
    'ORD_NO': rec.ORD_NO,
    'APP_NO': rec.APP_NO,
    'ACQ_CARD_NM': rec.ACQ_CARD_NM,
    'TRX_ST_CD': transStatus(rec.TRX_ST_CD),
    'L1_VID': rec.L1_VID,
    'L2_VID': rec.L2_VID,
    'L3_VID': rec.L3_VID,
    'VGRP_NM': rec.VGRP_NM,
    'VGRP_NM2': rec.VGRP_NM2,
    'VGRP_NM3': rec.VGRP_NM3,
    'TID': rec.TID,
    'ORD_NM': rec.ORD_NM,
    'ORD_HP': rec.ORD_HP
  }
})
  }
    newRowData.unshift(headerName);
    const book = xlsx.utils.book_new();
    const transList = xlsx.utils.json_to_sheet( newRowData

, { header :headers , skipHeader : true } );

xlsx.utils.book_append_sheet( book,transList);

xlsx.writeFile( book, '페이시스_거래내역.xlsx');
}
