import React, { useState ,useEffect} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import { Table, TableCell, TableRow ,TableBody } from '@mui/material';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { deleteSpecialChar } from '../../../common/deleteSpecialChar';
import { getCurrentDate } from '../../../common/getCurrentDate';


const getFormatDate = (date) => {
   var year = date.getFullYear();
   var month = 1 + date.getMonth();
   month = month >= 10 ? month : '0' + month;
   var day = date.getDate();
   day = day >= 10 ? day : '0' + day;
   return year + '/' + month + '/' + day;
};

  const SmsPay = () => {
   const navigate = useNavigate();
   const [cookies, setCookie, removeCookie] = useCookies();

   //현재시간 
   const currentDate = new Date();
   //material ui date max day
   const limitDate = new Date(Date.now() + 5*24*60*60*1000);
   //시작일자 ,종료일자
   const [endDate, setEndDate] = React.useState(getFormatDate(currentDate));

    function smsPayRequest(value){
      let check = /^[0-9]+$/;
      if (!value.ordNm) {
        swal({
           text: '구매자명을 확인해주세요',
           button: {
              text: '확인',
           },
        });
        return false;
     }
     if (!value.ordHp || value.ordHp.length < 9 || !check.test(value.ordHp)) {
        swal({
           text: '휴대폰번호 확인해주세요',
           button: {
              text: '확인',
           },
        });
        return false;
     }
     if (!value.goodsAmt) {
        swal({
           text: '승인금액을 확인해주세요',
           button: {
              text: '확인',
           },
        });
        return false;
     }
     if (!value.goodsNm) {
        swal({
           text: '상품명을 확인해주세요',
           button: {
              text: '확인',
           },
        });
        return false;
     }

      Swal.showLoading();
      axios
      .post(
         "https://pgapi.paysis.co.kr/api/sms",
         {
            usrTypeId : cookies.id,
            usrType:cookies.type,
            mid : cookies.id,
            ord_Msg : value.ordMsg,
            regist_GOODS_AMT: deleteSpecialChar(value.goodsAmt),
            regist_ORD_HP : deleteSpecialChar(value.ordHp),
            regist_ORD_NM :value.ordNm,
            regist_GOODS_NM: value.goodsNm,
            regist_LMT_DT : deleteSpecialChar(endDate),
            regist_ORD_NO :cookies.id+getCurrentDate()+"000mms"
         },
         {
            headers: {
               'Content-Type': 'application/json',
               'X-Requested-With': 'XMLHttpRequest',
            },
         }
      )
      .then(function (response) {
         if (response.data.res_cd != '0000') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            if (response.data.res_cd == '1004') {
              swal({
                text: response.data.res_msg,
                button: {
                  text: '확인',
                },
              }).then(function (){
               navigate('/login', { replace: false });
               Swal.close();
            });;
            }
          } else {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            }).then(function (){
               navigate('/app/smspaylist', { replace: false });
               Swal.close();
            });
          }
        }).catch(function (error) {
          Swal.close();
          swal({
            text: '미지정 오류',
            button: {
              text: '확인',
            },
          });
      });
    }


    return (
      <Formik
      initialValues={{
        goodsNm: '',
        goodsAmt: '',
        quotaMon: '',
        cardNo: '',
        expireYm: '',
        ordNm: '',
        ordHp: '',
        ordMsg : '',
      }}
      onSubmit={(value) => {
        smsPayRequest(value);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form  onSubmit={handleSubmit}>
          <Card>
          <CardHeader title="SMS요청" />
          <Divider />
          <Table size="small" aria-label="a dense table">
              <TableBody>
              <TableRow>
                    <TableCell style={{fontSize:'0.6rem'}}>
                    관리계정
                    </TableCell>
                    <TableCell>
                    <TextField id="mid" value={cookies.id} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" disabled />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                    <TableCell style={{fontSize:'0.6rem'}}>
                    구매자명
                    </TableCell>
                    <TableCell>
                    <TextField id="ordNm" value={values.ordNm} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                 <TableCell style={{fontSize:'0.6rem'}}>
                    상품명
                    </TableCell>
                    <TableCell>
                    <TextField   name="goodsNm"  value={values.goodsNm} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                 <TableCell style={{fontSize:'0.6rem'}}>
                    승인금액
                    </TableCell>
                    <TableCell>
                    <TextField name="goodsAmt"  value={values.goodsAmt} onBlur={handleBlur} onChange={handleChange}  size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                 <TableCell style={{fontSize:'0.6rem'}}>
                    휴대폰번호
                    </TableCell>
                    <TableCell>
                    <TextField id="ordHp" value={values.ordHp} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                 <TableCell style={{fontSize:'0.6rem'}}>
                    메모
                    </TableCell>
                    <TableCell>
                    <TextField id="ordMsg" value={values.ordMsg} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                 <TableCell style={{fontSize:'0.6rem'}}>
                    결제기한
                    </TableCell>
                    <TableCell>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                       maxDate={limitDate}
                       minDate={currentDate}
                      value={endDate}
                      inputFormat={"yyyy/MM/dd"}
                      mask={"____/__/__"}
                      onChange={(newValue) => {
                        setEndDate(getFormatDate(newValue));
                      }}
                      renderInput={(params) => <TextField   size='small'{...params} />}
                    />
                   </LocalizationProvider>
                    </TableCell>
                 </TableRow>
              </TableBody>
           
           </Table>
          </Card>
          <br></br>
          <div style={{color:'red',fontSize:'0.6rem'}}>* 결제기한 시간은 23시이며 기준 5일 이내 사용 가능합니다.</div>
          <br></br>
          <Card>
          </Card>
          <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    요청하기
            </Button>
        </form>
      )}
        </Formik>
      );
  };
  
  
  export default SmsPay;