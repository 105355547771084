import React, { useState , useContext, useEffect } from 'react';
import {
    Card,
    CardHeader,
  } from '@material-ui/core';
import GroupVidVidGidMidInfo from '../../../common/GroupVidVidGidMidInfo'
import Button from '@mui/material/Button';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { MyContext } from '../../../App';
import { useCookies } from 'react-cookie';
import { Navigate ,useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { FormControl } from '@material-ui/core';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ko from 'date-fns/locale/ko';
import { getFormatDate } from '../../../common/getFormatDate';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { deleteSpecialChar } from '../../../common/deleteSpecialChar';
import { numberWithCommasGrid } from '../../../common/grid/numberWithCommasGrid';
import { dateYYYYMMDDGrid } from '../../../common/grid/dateYYYYMMDDGrid';
import { dateTimeGrid } from '../../../common/grid/dateTimeGrid';
import { dateRange } from '../../../common/dateRange';

const NotiResend =() => {

   const { selectInfoList, setSelectInfoList } = useContext(MyContext);

    const [cookies, setCookie, removeCookie] = useCookies();
    const rowHeight = 40;
    const [rowData,setRowData] = useState();
    const navigate = useNavigate();

    const currentDate = new Date();
    //시작일자 ,종료일자
    const [startDate, setStartDate] = React.useState(getFormatDate(currentDate));
    const [endDate, setEndDate] = React.useState(getFormatDate(currentDate));
    //거래일자 ,취소일자
    const dateList = [{value : 'appdt' ,lavel : '거래일자'},{value :'ccdt',lavel :'취소일자'}];
    const [dateType, setDateType] = useState('appdt');

    //권한 show hide
    const [selectType, setSelectType] = useState('select');
    const [selectTypeList,setSelectTypeList] =useState(  
      [{value : 'select' ,lavel : '선택하세요'}
    ,{value : 'tid' ,lavel : 'TID'}
    ,{value :'mid',lavel :'MID'}
    ,{value :'app_no',lavel :'승인번호'}
  ]); 

      //날짜 버튼
      const dateButton = (e) => {
        const date = new Date();
        const dates = new Date();
        if(e.target.value == '0'){
          date.setDate(date.getDate() - 1);
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(date));
        }else if(e.target.value == '1'){
          date.setDate(date.getDate());
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(date));
        }else if(e.target.value == '2'){
          date.setDate(date.getDate() - 3);
          dates.setDate(dates.getDate());
          setStartDate(getFormatDate(date));
          setEndDate(getFormatDate(dates));
        }
      }
    //노티 리스트
    const notiResendList = () =>{
      let limitDay = dateRange(startDate,endDate);
      if(limitDay > 3) {
        swal({
          text: '시작일자와(과) 종료일자의 간격은 3일 이내여야 합니다.' ,
          button: {
            text: '확인',
          },
        });
        return false;
      }
      Swal.showLoading();
      axios
            .post(
               process.env.REACT_APP_API_HOST + "/mms/notiList",
               
               {
                  token : cookies.token,
                  userId : cookies.userid,
                  usrTypeId : cookies.id,
                  usrType:cookies.type,
                  l1_vid : selectInfoList.l1Vid,
                  l2_vid : selectInfoList.l2Vid,
                  l3_vid : selectInfoList.l3Vid,
                  mid : selectInfoList.mid,
                  startDate : deleteSpecialChar(startDate),
                  endDate :deleteSpecialChar(endDate),
                  dateType : dateType,
                  selectType : selectType,
                  textSelectType : document.getElementById('textSelectType').value
               },
               {
                  headers: {
                     'Content-Type': 'application/json',
                     'X-Requested-With': 'XMLHttpRequest',
                  },
               }
            )
            .then(function (response) {
               if (response.data.res_code != '0000') {
                 swal({
                   text: response.data.res_msg,
                   button: {
                     text: '확인',
                   },
                 });
                 if (response.data.res_code == '1004') {
                   swal({
                     text: response.data.res_msg,
                     button: {
                       text: '확인',
                     },
                   });
                   navigate('/login', { replace: false });
                 }
                 Swal.close();
               } else {
                  setRowData(response.data.data)
                 Swal.close();
               }
             }).catch(function (error) {
               Swal.close();
               swal({
                 text: '미지정 오류',
                 button: {
                   text: '확인',
                 },
               });
           });
           }



           //승인 노티 재전송
           const  NotiResendButton = (x) =>{
              notiResendUpdate(x.data,'0');
           }

           //취소노티 재전송
           const  ccNotiResendButton = (x) =>{
             if(x.data.TRX_ST_CD =='0'){
              swal({
                text: '거래상태가 취소 상태가 아닙니다.',
                button: {
                  text: '확인',
                },
              });
              return false;
             }else{
              notiResendUpdate(x.data,'1');
             }
          }
          
          //실제 노티 업데이트 이벤트
          const notiResendUpdate = (data,type) =>{
            Swal.showLoading();
            axios
                  .post(
                     process.env.REACT_APP_API_HOST + "/mms/notiResendUpdate",
                     {
                        userId : cookies.userid,
                        usrTypeId : cookies.id,
                        usrType:cookies.type,
                        token : cookies.token,
                        tid : data.TID,
                        seq : data.URL_SEQ,
                        type : type
                     },
                     {
                        headers: {
                           'Content-Type': 'application/json',
                           'X-Requested-With': 'XMLHttpRequest',
                        },
                     }
                  )
                  .then(function (response) {
                     if (response.data.res_code != '0000') {
                       swal({
                         text: response.data.res_msg,
                         button: {
                           text: '확인',
                         },
                       });
                       if (response.data.res_code == '1004') {
                         swal({
                           text: response.data.res_msg,
                           button: {
                             text: '확인',
                           },
                         });
                         navigate('/login', { replace: false });
                       }
                       Swal.close();
                     } else {
                      swal({
                        text: '재전송 완료하였습니다. 최소 3분후 확인 부탁드립니다.',
                        button: {
                          text: '확인',
                        },
                      });
                       Swal.close();
                     }
                   }).catch(function (error) {
                     Swal.close();
                     swal({
                       text: '미지정 오류',
                       button: {
                         text: '확인',
                       },
                     });
                 });
                 }

           function textChange(){
             return '<button>재전송</button>';
           }


       useEffect(() => {
       },[]);

    return (
      <>
        <div style={{float: 'right'}}>
        <Button  size="small" variant="contained" color="primary" onClick={notiResendList}>
           조회
        </Button>
        </div>
        <br></br>
        <br></br>
        <GroupVidVidGidMidInfo/>
        <br></br>
         <Card>
         <FormControl  sx={{ m: 1, width: 250 }}>
          <Select size='small'
              //  maxDate={currentDate}
                defaultValue={'appdt'}
                value={dateList.value}
                inputProps={{
                  id: 'selectDateType',
                }}
                onChange={(event) =>{
                  setDateType(event.target.value);
                  }}
                style={{marginRight:'15px'}}
              >
                 {dateList?.map(option => {
              return (
              <MenuItem key={option} value={option.value}>{option.lavel}</MenuItem>
                );
             })}
            </Select>
            </FormControl>
         <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
            <DatePicker
              value={startDate}
              inputFormat={"yyyy/MM/dd"}
              mask={"____/__/__"}
              onChange={(newValue) => {
                setStartDate(getFormatDate(newValue));
              }}
              renderInput={(params) => <TextField   style={{width:'150px'}} size='small'{...params} />}
            />
          </LocalizationProvider>
           ~ 
           <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
            <DatePicker
            //  maxDate={currentDate}
              value={endDate}
              inputFormat={"yyyy/MM/dd"}
              mask={"____/__/__"}
              onChange={(newValue) => {
                setEndDate(getFormatDate(newValue));
              }}
              renderInput={(params) => <TextField  
                style={{width:'150px'}} size='small'{...params} />}
            />
          </LocalizationProvider>
          
          <Button size="medium" style={{marginLeft:'10px'}} value="0" onClick={dateButton} variant="outlined" color="primary">어제</Button>
          <Button size="medium" style={{marginLeft:'10px'}} value="1" onClick={dateButton} variant="outlined" color="primary">오늘</Button>
          <Button size="medium" style={{marginLeft:'10px'}} value="2" onClick={dateButton} variant="outlined" color="primary">3일</Button>

        <FormControl  sx={{ m: 1, width: 250 }}>
            <Select size='small'
                defaultValue={'select'}
                value={selectTypeList.value}
                inputProps={{
                  id: 'selectTypeList',
                }}
                onChange={(event) =>{
                  setSelectType(event.target.value);
                  }}
                  style={{marginLeft:'30px'}}
              >
                 {selectTypeList?.map(option => {
              return (
              <MenuItem key={option} value={option.value}>{option.lavel}</MenuItem>
                );
             })}
            </Select>
          </FormControl>
            <TextField  id="textSelectType"  style={{marginLeft:'30px'}} size="small" variant="outlined" />
            </Card>
            <br>
            </br>
        <Card>
        <CardHeader title="노티 리스트" />
          <div className="ag-theme-alpine" style={{height: 520, width: '100%'}}>
           <AgGridReact rowHeight={rowHeight}
           pagination={true}
           enableCellTextSelection={true}
           paginationPageSize={10}
            defaultColDef={{ resizable: true }}
            debounceVerticalScrollbar={true}
               rowData={rowData}>
               <AgGridColumn  width={130} headerName = "MID" field="MID"></AgGridColumn>
               <AgGridColumn  width={270} headerName = "TID" field="TID"></AgGridColumn>
               <AgGridColumn  width={100} headerName = "금액" field="GOODS_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
               <AgGridColumn  width={100} headerName = "승인번호" field="APP_NO"></AgGridColumn>
               <AgGridColumn  width={120} headerName = "승인일자" field="APP_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
               <AgGridColumn  width={100} headerName = "승인시간" field="APP_TM" cellRenderer={dateTimeGrid} ></AgGridColumn>
               <AgGridColumn  width={130} headerName = "승인 재전송" cellRenderer={textChange}  onCellClicked={NotiResendButton}></AgGridColumn>
               <AgGridColumn  width={120} headerName = "취소일자" field="CC_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
               <AgGridColumn  width={100} headerName = "취소시간" field="CC_TM" cellRenderer={dateTimeGrid}></AgGridColumn>
               <AgGridColumn  width={130} headerName = "취소 재전송" cellRenderer={textChange}  onCellClicked={ccNotiResendButton} ></AgGridColumn>
               <AgGridColumn  width={330} headerName = "목적지URL" field="SND_TARGET"></AgGridColumn>
           </AgGridReact>
       </div>
       </Card>
       </>
    );

}

export default NotiResend;