import React from 'react';

function StyledLink() {
    const handleLinkClick = (url) => {
        window.open(url, 'terms','width=985,height=630,scrollbars=yes');
    };

    return (
        <div style={{ paddingRight: "15px", paddingLeft: "15px" }}>
      <span
          onClick={() => handleLinkClick("http://paysis.co.kr/privacy")}
          style={{
              paddingLeft: "0px",
              display: "inline-block",
              color: "#666",
              fontSize: "14px",
              marginTop: "5px",
              paddingRight: "10px",
              borderRight: "1px solid #ddd",
              textDecoration: "none",
              fontWeight: "bold",
              cursor: "pointer"
          }}
      >
        개인정보처리방침
      </span>
            <span
                onClick={() => handleLinkClick("http://paysis.co.kr/service")}
                style={{
                    paddingLeft: "10px",
                    display: "inline-block",
                    color: "#666",
                    fontSize: "14px",
                    marginTop: "5px",
                    paddingRight: "10px",
                    textDecoration: "none",
                    fontWeight: "bold",
                    cursor: "pointer"
                }}
            >
        이용약관
      </span>
        </div>
    );
}

export default StyledLink;
