import React, { useState , useEffect,useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  Hidden,
  makeStyles
} from '@material-ui/core';
import { Table, TableCell, TableRow ,TableBody } from '@mui/material';
import axios from 'axios';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import FormControl from '@mui/material/FormControl';
import { display, style } from '@mui/system';
import { useCookies } from 'react-cookie';
import { MyContext } from '../App';
import Swal from 'sweetalert2';
const useStyles = makeStyles(({
  root: {},
}));


const GroupVidVidGidMidInfo = () => {
  
  const { selectInfoList, setSelectInfoList } = useContext(MyContext);

  const [cookies, setCookie, removeCookie] = useCookies();

  //selected value 정의
  const [l1Value, setL1Value] = useState("all");
  const [l2Value, setL2Value] = useState("all");
  const [l3Value, setL3Value] = useState("all");
  const [gidValue, setGidValue] = useState("all");
  const [midValue, setMidValue] = useState("all");

  //selected value list 정의
  const [l1Vid, setl1Vid] = React.useState();
  const [l2Vid, setl2Vid] = React.useState();
  const [l3Vid, setl3Vid] = React.useState();
  const [gid, setGid] = React.useState();
  const [mid, setMid] = React.useState();

  //usrType 맞는 화면 show hide
  const [l1vidDisplay , setl1vidDisplay] = React.useState(false);
  const [l2vidDisplay , setl2vidDisplay] = React.useState(false);
  const [l3vidDisplay , setl3vidDisplay] = React.useState(false);
  const [gidDisplay , setgidDisplay] = React.useState(false);
   

  function styleDisplay(usrType){
      if(usrType == 4 ){
        setl1vidDisplay(true);
        setgidDisplay(true);
      }else if( usrType == 3 ){
        setl1vidDisplay(true);
        setl2vidDisplay(true);
        setgidDisplay(true);
      }else if( usrType == 1 ){
        setl1vidDisplay(true);
        setl2vidDisplay(true);
        setl3vidDisplay(true);
        setgidDisplay(true);
      }else if(usrType == 2 ){
        setl1vidDisplay(true);
        setl2vidDisplay(true);
        setl3vidDisplay(true);
      }else{
        setgidDisplay(true);
      }
  }
  
  
  
  //총판명  onchange시 함수 호출
  function l1VidSelect(event) {
      setL1Value(event.target.value);
      setL2Value('all');
      setL3Value('all');
      setMidValue('all');
      setl2Vid(null);
      setl3Vid(null);
      setMid(null);
      setSelectInfoList({'l1Vid' :event.target.value , 'l2Vid' :'all' , 'l3Vid' :'all', 'mid' :'all' });
      login(event.target.value,"l1vid");
  }

  //대리점  onchange시 함수 호출
  function l2VidSelect(event) {
      setL2Value(event.target.value);
      setL3Value('all');
      setMidValue('all');
      setl3Vid(null);
      setMid(null);
      setSelectInfoList({'l1Vid' :l1Value , 'l2Vid' :event.target.value, 'l3Vid' :'all', 'mid' :'all'});
      login(event.target.value,"l2vid");
  }


  //딜러  onchange시 함수 호출
  function l3VidSelect(event) {
      setL3Value(event.target.value);
      setMidValue('all');
      setMid(null);
      setSelectInfoList({'l1Vid' :l1Value , 'l2Vid' :l2Value, 'l3Vid' :event.target.value, 'mid' :'all'});
      login(event.target.value,"l3vid");
  }

    //그룹명  onchange시 함수 호출
    function gidSelect(event) {
      setGidValue(event.target.value);
      setMidValue('all');
      setMid(null);
      setSelectInfoList({'l1Vid' :l1Value , 'l2Vid' :l2Value , 'l3Vid' :l3Value, 'gid' :event.target.value ,'mid' :'all'});
      login(event.target.value,"gid");
  }

    
  //가맹점  onchange시 함수 호출
  function midSelect(event) {
    setMidValue(event.target.value);
    setSelectInfoList({'l1Vid' :l1Value , 'l2Vid' :l2Value, 'l3Vid' :l3Value, 'gid' : gidValue, 'mid' :event.target.value });
  }

  function login(value,selectType){
    Swal.showLoading();
    axios
    .post(
       process.env.REACT_APP_API_HOST + "/mms/usrType",
       {
          usrType:cookies.type,
          userId : cookies.userid,
          selectType : selectType,
          id : value,
          usrTypeId : cookies.id,
          token : cookies.token
       },
       {
          headers: {
             'Content-Type': 'application/json',
             'X-Requested-With': 'XMLHttpRequest',
          },
       }
    )
    .then(function (response) {
      if(response.data.selectType == 'gvid' || response.data.selectType == 'vid'){
        setl1Vid(response.data.data);
        setMid(response.data.dataM);
      }else if(response.data.selectType == 'l1vid'){
        setl2Vid(response.data.data);
        setMid(response.data.dataM);
      }else if(response.data.selectType == 'l2vid'){
        setl3Vid(response.data.data);
        setMid(response.data.dataM);
      }else if(response.data.selectType == 'l3vid'){
        setMid(response.data.dataM);
      }else if(response.data.selectType == 'gid'){
        setGid(response.data.data)
        setMid(response.data.dataM);
      }
      Swal.close();
    });
   }
  useEffect(() => {
    setSelectInfoList({'l1Vid' :l1Value , 'l2Vid' :l2Value, 'l3Vid' :l3Value, 'mid' :midValue});
    styleDisplay(cookies.type);
    if(cookies.type == '9'){
      login('',"gvid");
    }else if (cookies.type == '5'){
      login('',"vid");
    }else if (cookies.type == '4'){
      login('',"l1vid");
    }else if (cookies.type == '3'){
      login('',"l2vid");
    }else if (cookies.type == '2'){
      login('',"gid");
    }else if (cookies.type == '1'){
      login('',"l3vid");
    }
  },[]);

  return (
    <>
      <Card>
      <Table  size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell style={l1vidDisplay  ? {display:'none'} : {display: 'default'}}>
              총판
            </TableCell>
            <TableCell style={l1vidDisplay  ? {display:'none'} : {display: 'default'}}>
            <FormControl  sx={{ m: 1, width: 250 }}>
            <Select 
            id={'selectL1Vid'}
            onChange={l1VidSelect}
            value={l1Value}
            defaultValue={l1Value}
            >
            <MenuItem value="all">모두</MenuItem>
              {l1Vid?.map(option => {
              return (
              <MenuItem key={option.L1_VID} value={option.L1_VID}>
                  {option.L1_VID + "||" + option.VGRP_NM}
              </MenuItem>
                );
             })}
            </Select>
            </FormControl>
            </TableCell>
            <TableCell style={l2vidDisplay  ? {display:'none'} : {display: 'default'}}>
              대리점
            </TableCell>
            <TableCell style={l2vidDisplay  ? {display:'none'} : {display: 'default'}}>
            <FormControl sx={{ m: 1, width: 250 }}>
            <Select
              onChange={l2VidSelect}
              id={'selectL2Vid'}
              value={l2Value}
              defaultValue={l2Value}
            >
            <MenuItem value="all">모두</MenuItem>
              {l2Vid?.map(option => {
              return (
              <MenuItem key={option.L2_VID} value={option.L2_VID}>
                  {option.L2_VID + "||" + option.VGRP_NM}
              </MenuItem>
                );
             })}
            </Select>
            </FormControl>
            </TableCell>
            <TableCell style={l3vidDisplay  ? {display:'none'} : {display: 'default'}}>
              딜러
            </TableCell>
            <TableCell style={l3vidDisplay  ? {display:'none'} : {display: 'default'}}>
            <FormControl sx={{ m: 1, width: 250 }}>
            <Select
               onChange={l3VidSelect}
               id={'selectL3Vid'}
               value={l3Value}
               defaultValue={l3Value}
            >
            <MenuItem value="all">모두</MenuItem>
              {l3Vid?.map(option => {
              return (
              <MenuItem key={option.L3_VID} value={option.L3_VID}>
                  {option.L3_VID + "||" + option.VGRP_NM}
              </MenuItem>
                );
             })}
            </Select>
            </FormControl>
            </TableCell>
            <TableCell style={gidDisplay  ? {display:'none'} : {display: 'default'}}>
              그룹명
            </TableCell>
            <TableCell style={gidDisplay  ? {display:'none'} : {display: 'default'}}>
            <FormControl sx={{ m: 1, width: 250 }}>
            <Select
               onChange={gidSelect}
               id={'selectGid'}
               value={gidValue}
               defaultValue={gidValue}
            >
            <MenuItem value="all">모두</MenuItem>
              {gid?.map(option => {
              return (
              <MenuItem key={option.GID} value={option.GID}>
                  {option.GID + "||" + option.G_NM}
              </MenuItem>
                );
             })}
            </Select>
            </FormControl>
            </TableCell>
            <TableCell >
              가맹점
            </TableCell>
            <TableCell>
            <FormControl sx={{ m: 1, width: 250 }}>
            <Select
                onChange={midSelect}
                id={'selectMid'}
                value={midValue}
                defaultValue={midValue}
            >
            <MenuItem value="all">모두</MenuItem>
              {mid?.map(option => {
              return (
              <MenuItem key={option.MID} value={option.MID}>
                  {option.MID +"||"+option.CO_NM }
              </MenuItem>
                );
             })}
            </Select>
            </FormControl>
            </TableCell>
          </TableRow>
          </TableBody>
       </Table>
      </Card>
    </>
  );
};

GroupVidVidGidMidInfo.propTypes = {
  className: PropTypes.string
};

export default GroupVidVidGidMidInfo;
