import React from 'react';
import {
    Container,
    makeStyles,
    Grid
} from '@material-ui/core';
import Page from '../../components/Page';
import Notice from "./notice";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const NoticeView = () => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="고객지원"
        >
            <Container maxWidth={false}>
                <Grid
                    // container
                    // spacing={3}
                    // justify='center'
                >
                    <Grid
                        item
                        lg={12}
                        sm={12}
                        xl={12}
                        xs={12}
                    >
                    <Notice />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default NoticeView;
