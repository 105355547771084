import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { numberWithCommas } from '../../../common/numberWithCommas';
import { quotaMon } from '../../../common/quotaMon';
import { dateYYYYMMDD } from '../../../common/dateYYYYMMDD';
import { dateTime } from '../../../common/dateTime';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import { border, borderBottom } from '@mui/system';

export default (props) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [open, setOpen] = React.useState(false);
  const [selectTransReceiptData, setSelectTransReceiptoData] = useState({});
  const navigate = useNavigate();

  const handleClickOpen = () => {
    selectTransReceipt();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectTransReceipt = () => {
    axios
      .post(
        process.env.REACT_APP_API_HOST + "/mms/selectTransReceipt",

        {
          token: cookies.token,
          usrTypeId: cookies.id,
          usrType: cookies.type,
          userId : cookies.userid,
          tid: cellValue
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      )
      .then(function (response) {
        if (response.data.res_code != '0000') {
          swal({
            text: response.data.res_msg,
            button: {
              text: '확인',
            },
          });
          if (response.data.res_code == '1004') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            navigate('/login', { replace: false });
          }
          Swal.close();
        } else {
          setSelectTransReceiptoData(response.data.data);
          setOpen(true);
        }
      }).catch(function (error) {
        swal({
          text: '미지정 오류',
          button: {
            text: '확인',
          },
        });
      });
  }

  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  const styles = { fontSize: 11, width: '100%' }

  

  return (
    <>
      <Button onClick={handleClickOpen}  variant="outlined" style={{width:'100%'}} >조회</Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>영수증<span style={{ color: 'red' }}>({selectTransReceiptData.TRX_ST_CD == '0' ? '승인' : '취소'})</span></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <table style={styles}>
              <tr >
                <th align='left' style={{ color: 'blue' }}>공급자정보</th>
              </tr>
              <tr>
                <th align='left'>상호</th>
                <td align='right'>{selectTransReceiptData.CO_NM}</td>
              </tr>
              <tr>
                <th align='left'>대표자</th>
                <td align='right'>{selectTransReceiptData.REP_NM}</td>
              </tr>
              <tr>
                <th align='left'>사업자번호</th>
                <td align='right'>{selectTransReceiptData.CO_NO}</td>
              </tr>
              <tr>
                <th align='left'>전화번호</th>
                <td align='right'>{selectTransReceiptData.TEL_NO}</td>
              </tr>
              <tr>
                <th align='left'>주소</th>
                <td align='right'>{selectTransReceiptData.ADDR_NO1} {selectTransReceiptData.ADDR_NO2}</td>
              </tr>
            </table>
            <br></br>
            <table style={styles}>
              <tr >
                <th align='left' style={{ color: 'blue' }}>결제정보</th>
              </tr>
              <tr>
                <th align='left'>총 결제금액</th>
                <td align='right'>{numberWithCommas(selectTransReceiptData.GOODS_AMT)}</td>
              </tr>
              <tr>
                <th align='left'>금 액</th>
                <td align='right'>{numberWithCommas(selectTransReceiptData.GOODS_SPL_AMT)}</td>
              </tr>
              <tr>
                <th align='left'>부가세</th>
                <td align='right'>{numberWithCommas(selectTransReceiptData.GOODS_VAT)}</td>
              </tr>
              <tr>
                <th align='left'>할부개월</th>
                <td align='right'>{selectTransReceiptData.QUOTA_MON}</td>
              </tr>
              <tr>
                <th align='left'>구매자</th>
                <td align='right'>{selectTransReceiptData.ORD_NM}</td>
              </tr>
              <tr>
                <th align='left'>카드번호</th>
                <td align='right'>{selectTransReceiptData.CARD_NO}</td>
              </tr>
              <tr>
                <th align='left'>승인번호</th>
                <td align='right'>{selectTransReceiptData.APP_NO}</td>
              </tr>
              <tr>
                <th align='left'>상품명</th>
                <td align='right'>{selectTransReceiptData.GOODS_NM}</td>
              </tr>
              <tr>
                <th align='left'>거래일시</th>
                <td align='right'>{dateYYYYMMDD(selectTransReceiptData.APP_DT)} {dateTime(selectTransReceiptData.APP_TM)}</td>
              </tr>
              <tr>
                <th align='left'>취소일시</th>
                <td align='right'>{dateYYYYMMDD(selectTransReceiptData.CC_DT)} {dateTime(selectTransReceiptData.CC_TM)}</td>
              </tr>
            </table>

            <br></br>
            <table style={styles}>
              <tr >
                <th align='left' style={{ color: 'blue' }}>결제대행사정보</th>
              </tr>
              <tr>
                <th align='left'>상 호</th>
                <td align='right'>주식회사 페이시스</td>
              </tr>
              <tr>
                <th align='left'>대표자</th>
                <td align='right'>박종식</td>
              </tr>
              <tr>
                <th align='left'>전화번호</th>
                <td align='right'>070-4322-4809</td>
              </tr>
              <tr>
                <th align='left'>주 소</th>
                <td align='right'>서울 성동구 성수일로77  <br></br>서울숲 IT밸리 714호</td>
              </tr>
            </table>
            <br></br>
            <div style={{ fontSize: 12, width: '100%' }}>부가가치 세법 제46조 3항에 따라 신용카드 매출전표로
              <br></br>매입계산서로 사용할 수 있으며 부가가치세법 제 33조 2항에
              <br></br>따라 별도의 세금계산서를 교부하지 않습니다.
            </div>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};