import React, { useState, useContext, useEffect } from 'react';
import {
  Card,
  Divider,
  CardHeader
} from '@material-ui/core';
import { Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material';
import GroupVidVidGidMidInfo from '../../../common/GroupVidVidGidMidInfo';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl } from '@material-ui/core';
import { getFormatDate } from '../../../common/getFormatDate';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { deleteSpecialChar } from '../../../common/deleteSpecialChar';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ReceiptButton from './ReceiptButton';
import TransDetailButton from './TransDetailButton';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { checkPgGrid } from '../../../common/grid/checkPgGrid';
import { numberWithCommas } from '../../../common/numberWithCommas';
import { MyContext } from '../../../App';
import { dateRange } from '../../../common/dateRange';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { Navigate, useNavigate } from 'react-router-dom';
import { dateYYYYMMDDGrid } from '../../../common/grid/dateYYYYMMDDGrid';
import { dateTimeGrid } from '../../../common/grid/dateTimeGrid';
import { numberWithCommasGrid } from '../../../common/grid/numberWithCommasGrid';
import { quotaMoanGrid } from '../../../common/grid/quotaMonGrid';
import { transStatusGrid } from '../../../common/grid/transStatusGrid';
import { transExcel } from '../../../common/excel/transExcel';
import { getCurrentDateYYYYMMDD } from '../../../common/getCurrentDateYYYYMMDD';
import ko from 'date-fns/locale/ko';

const Trans = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  //선택하세요 
  const [selectTypeList, setSelectTypeList] = useState([{}]);
  //선택하세요 값 Value
  const [selectType, setSelectType] = useState('select');

  const { selectInfoList, setSelectInfoList } = useContext(MyContext);

  const currentDate = new Date();
  //시작일자 ,종료일자
  const [startDate, setStartDate] = React.useState(getFormatDate(currentDate));
  const [endDate, setEndDate] = React.useState(getFormatDate(currentDate));
  //거래일자 ,취소일자
  const dateList = [{ value: 'appdt', lavel: '거래일자' }, { value: 'ccdt', lavel: '취소일자' }];
  const [dateType, setDateType] = useState('appdt');

  //권한 show hide
  const [l1vidDisplay, setl1vidDisplay] = React.useState(false);
  const [l2vidDisplay, setl2vidDisplay] = React.useState(false);
  const [l3vidDisplay, setl3vidDisplay] = React.useState(false);

  const today = getCurrentDateYYYYMMDD();

  const cancelText = (params) => {
    if (params.data.APP_DT == today && cookies.banccflg != "1") {
      return "<Button>취소</Button>";
    } else {
      return "-";
    }
  };

  //1차PG사
  const selectPgList = (
    [{ value: 'all', lavel: '모두' }
      , { value: '01', lavel: '코페이' }
      , { value: '02', lavel: '올앳' }
      , { value: '03', lavel: '다날' }
      , { value: '04', lavel: '갤컴' }
      , { value: '05', lavel: 'KIS' }
      , { value: '06', lavel: '토스' }
      , { value: '07', lavel: 'KSNET' }
      , { value: '08', lavel: '섹타나인' }
    ]);
  const [selectPgType, setSelectPgType] = useState('all');

  //매입카드사
  const selectCardList = (
    [{ value: 'all', lavel: '모두' }
      , { value: '01', lavel: '비씨' }
      , { value: '02', lavel: '국민' }
      , { value: '03', lavel: '하나' }
      , { value: '04', lavel: '삼성' }
      , { value: '06', lavel: '신한' }
      , { value: '07', lavel: '현대' }
      , { value: '08', lavel: '롯데' }
      , { value: '12', lavel: '농협' }
      , { value: '15', lavel: '우리' }
    ]);
  const [selectCardType, setSelectCardType] = useState('all');



  //거래상태
  const selectTrxStCdList = (
    [{ value: 'all', lavel: '모두' }
      , { value: '0', lavel: '승인' }
      , { value: '1', lavel: '전취소' }
      , { value: '2', lavel: '후취소' }
    ]);
  const [selectTrxStCdType, setSelectTrxStCdType] = useState('all');

  //권한에 따라 show hide 설정
  function styleDisplay(usrType) {
    if (usrType == 4) {
      setl1vidDisplay(true);
      setSelectTypeList(
        [{ value: 'select', lavel: '선택하세요' }
          , { value: 'cono', lavel: '사업자번호' }
          , { value: 'l2vid', lavel: '대리점' }
          , { value: 'l3vid', lavel: '딜러' }
          , { value: 'l2vidNm', lavel: '대리점명' }
          , { value: 'l3vidNm', lavel: '딜러명' }
          , { value: 'mid', lavel: 'MID' }
          , { value: 'gid', lavel: 'GID' }
          ,{value :'catId',lavel :'단말기번호'}
        ]);
    } else if (usrType == 3) {
      setl1vidDisplay(true);
      setl2vidDisplay(true);
      setSelectTypeList(
        [{ value: 'select', lavel: '선택하세요' }
          , { value: 'cono', lavel: '사업자번호' }
          , { value: 'l3vid', lavel: '딜러' }
          , { value: 'l3vidNm', lavel: '딜러명' }
          , { value: 'mid', lavel: 'MID' }
          , { value: 'gid', lavel: 'GID' }
          ,{value :'catId',lavel :'단말기번호'}
        ]);
    } else if (usrType == 1) {
      setl1vidDisplay(true);
      setl2vidDisplay(true);
      setl3vidDisplay(true);
      setSelectTypeList(
        [{ value: 'select', lavel: '선택하세요' }
          , { value: 'cono', lavel: '사업자번호' }
          , { value: 'mid', lavel: 'MID' }
          , { value: 'gid', lavel: 'GID' }
          ,{value :'catId',lavel :'단말기번호'}
        ]);
    } else {
      setSelectTypeList([{ value: 'select', lavel: '선택하세요' }
        , { value: 'cono', lavel: '사업자번호' }
        , { value: 'vid', lavel: '총판' }
        , { value: 'l2vid', lavel: '대리점' }
        , { value: 'l3vid', lavel: '딜러' }
        , { value: 'vidNm', lavel: '총판명' }
        , { value: 'l2vidNm', lavel: '대리점명' }
        , { value: 'l3vidNm', lavel: '딜러명' }
        , { value: 'mid', lavel: 'MID' }
        , { value: 'gid', lavel: 'GID' }
        ,{value :'catId',lavel :'단말기번호'}
      ]);
    }
  }

  //날짜 버튼
  const dateButton = (e) => {
    const date = new Date();
    const dates = new Date();
    if (e.target.value == '0') {
      date.setDate(date.getDate() - 1);
      setStartDate(getFormatDate(date));
      setEndDate(getFormatDate(date));
    } else if (e.target.value == '1') {
      date.setDate(date.getDate());
      setStartDate(getFormatDate(date));
      setEndDate(getFormatDate(date));
    } else if (e.target.value == '2') {
      date.setDate(date.getDate() - 7);
      dates.setDate(dates.getDate());
      setStartDate(getFormatDate(date));
      setEndDate(getFormatDate(dates));
    } else if (e.target.value == '3') {
      date.setDate(date.getDate() - 31);
      dates.setDate(dates.getDate());
      setStartDate(getFormatDate(date));
      setEndDate(getFormatDate(dates));
    }
  }



  //그리드 사이즈
  const rowHeight = 40;
  //거래 리스트 data
  const [rowData, setRowData] = useState([]);

  //거래 리스트 total
  const [rowDataTotal, setRowDataTotal] = useState({});

  //거래내역 리스트 조회
  const selectTransList = () => {

    let limitDay = dateRange(startDate, endDate);
    if (limitDay > 100) {
      swal({
        text: '시작일자와(과) 종료일자의 간격은 100일 이내여야 합니다.',
        button: {
          text: '확인',
        },
      });
      return false;
    }
    Swal.showLoading();
    axios.
      post(process.env.REACT_APP_API_HOST + "/mms/selectTransList"
        , {
          token: cookies.token,
          userId : cookies.userid,
          usrTypeId: cookies.id,
          usrType: cookies.type,
          l1_vid: selectInfoList.l1Vid,
          l2_vid: selectInfoList.l2Vid,
          l3_vid: selectInfoList.l3Vid,
          mid: selectInfoList.mid,
          startDate: deleteSpecialChar(startDate),
          endDate: deleteSpecialChar(endDate),
          dateType: dateType,
          selectPgType: selectPgType,
          selectCardType: selectCardType,
          selectTrxStCdType: selectTrxStCdType,
          selectType: selectType,
          textSelectType: document.getElementById('textSelectType').value,
          textTid: document.getElementById('textTid').value,
          textAppNo: document.getElementById('textAppNo').value,
          textGoodsNm: document.getElementById('textGoodsNm').value,
          textCatId: document.getElementById('textCatId').value
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      ).then(function (response) {
        if (response.data.res_code != '0000') {
          swal({
            text: response.data.res_msg,
            button: {
              text: '확인',
            },
          });
          if (response.data.res_code == '1004') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            navigate('/login', { replace: false });
          }
          Swal.close();
        } else {
          setRowData(response.data.data);
          setRowDataTotal(response.data.total);
          Swal.close();
        }
      }).catch(function (error) {
        Swal.close();
        swal({
          text: '미지정 오류',
          button: {
            text: '확인',
          },
        });
      });
  }

  //엑셀 다운로드
  function excelDownLoad() {
    transExcel(rowData, cookies.type);
  }


  //취소
  function cancel(data) {
    if (data.data.APP_DT != today) {
      return false;
    } else if (cookies.banccflg == 1) {
      return false;
    }
    // if (data.data.CONN_CD == '0003') {
    //   swal({
    //     text: '단말기 거래건은 취소가 불가합니다.',
    //     button: {
    //       text: '확인',
    //     },
    //   });
    //   return false;
    // }
    // if (data.data.TRX_ST_CD != 0) {
    //   swal({
    //     text: '해당 거래건은 승인상태가 아닙니다.',
    //     button: {
    //       text: '확인',
    //     },
    //   });
    //   return false;
    // }

    Swal.fire({
      text: '정말 취소하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '예',
      cancelButtonText: '아니요'
    }).then((result) => {
      if (result.isConfirmed) {
        cancelAjax(data);
      }
    })
  }

  //취소 ajax
  function cancelAjax(data) {
    Swal.showLoading();
    axios.
      post("https://pgapi.paysis.co.kr/api/cancel"
        , {
          ordNo: data.data.ORD_NO,
          canAmt: data.data.GOODS_AMT,
          mid: data.data.MID,
          canNm: cookies.id,
          canMsg: 'mms요청 취소',
          uid: cookies.userid,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      ).then(function (response) {
        if (response.data.res_code != '0000') {
          swal({
            text: response.data.res_msg,
            button: {
              text: '확인',
            },
          });
          Swal.close();
        } else {
          swal({
            text: response.data.res_msg,
            button: {
              text: '확인',
            },
          }).then(() => {
            Swal.close();
          });
        }
        Swal.close();
      }).catch(function (error) {
        Swal.close();
        swal({
          text: '미지정 오류',
          button: {
            text: '확인',
          },
        });
      });
  }

  useEffect(() => {
    styleDisplay(cookies.type);
    // selectTransList();
  }, []);

  return (
    <>
      <div style={{ float: 'right' }}>
        <Button size="small" variant="contained" onClick={selectTransList} color="primary">조회</Button>
        {/* <Button size="small" variant="contained"  onClick={selectTransListExcel} color="success">EXCEL</Button> */}
        {/* <CSVLink data={rowData} headers={headers}>
          <button>DownloadCsv</button>
        </CSVLink> */}
        <Button size="small" variant="contained" onClick={excelDownLoad} color="success">excel</Button>

      </div>
      <br></br>
      <br></br>
      <GroupVidVidGidMidInfo />
      <br></br>
      <Card>
        <FormControl sx={{ m: 1, width: 250 }}>
          <Select size='small'
            //  maxDate={currentDate}
            defaultValue={'appdt'}
            value={dateList.value}
            inputProps={{
              id: 'selectDateType',
            }}
            onChange={(event) => {
              setDateType(event.target.value);
            }}
            style={{ marginRight: '15px' }}
          >
            {dateList?.map(option => {
              return (
                <MenuItem key={option} value={option.value}>{option.lavel}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
          <DatePicker
            value={startDate}
            inputFormat={"yyyy/MM/dd"}
            mask={"____/__/__"}
            onChange={(newValue) => {
              setStartDate(getFormatDate(newValue));
            }}
            renderInput={(params) => <TextField style={{ width: '150px' }} size='small'{...params} />}
          />
        </LocalizationProvider>
        ~
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
          <DatePicker
            //  maxDate={currentDate}
            value={endDate}
            inputFormat={"yyyy/MM/dd"}
            mask={"____/__/__"}
            onChange={(newValue) => {
              setEndDate(getFormatDate(newValue));
            }}
            renderInput={(params) => <TextField
              style={{ width: '150px' }} size='small'{...params} />}
          />
        </LocalizationProvider>

        <Button size="medium" style={{ marginLeft: '10px' }} value="0" onClick={dateButton} variant="outlined" color="primary">어제</Button>
        <Button size="medium" style={{ marginLeft: '10px' }} value="1" onClick={dateButton} variant="outlined" color="primary">오늘</Button>
        <Button size="medium" style={{ marginLeft: '10px' }} value="2" onClick={dateButton} variant="outlined" color="primary">일주일</Button>
        <Button size="medium" style={{ marginLeft: '10px' }} value="3" onClick={dateButton} variant="outlined" color="primary">1개월</Button>

        {/* 선택하세요 */}
        <FormControl sx={{ m: 1, width: 250 }}>
          <Select size='small'
            defaultValue={'select'}
            value={selectTypeList.value}
            inputProps={{
              id: 'selectTypeList',
            }}
            onChange={(event) => {
              setSelectType(event.target.value);
            }}
            style={{ marginLeft: '30px' }}
          >
            {selectTypeList?.map((option ,idx) => {
              return (
                <MenuItem key={idx} value={option.value}>{option.lavel}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField id="textSelectType" style={{ marginLeft: '30px' }} size="small" variant="outlined" />
      </Card>
      <br></br>
      <Card>
        <Table size="small" aria-label="a dense table">
          <TableRow>
            <TableCell>
              1차PG사
            </TableCell>
            <TableCell>
              <FormControl>
                <Select
                  size='small'
                  value={selectPgList.value}
                  defaultValue={selectPgType}
                  onChange={(event) => {
                    setSelectPgType(event.target.value);
                  }}
                  style={{ minWidth: '250px' }}
                >
                  {selectPgList?.map((option ,idx)=> {
                    return (
                      <MenuItem key={idx} value={option.value}>{option.lavel}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              매입카드사
            </TableCell>
            <TableCell>
              <FormControl>
                <Select
                  size='small'
                  value={selectCardList.value}
                  defaultValue={selectCardType}
                  onChange={(event) => {
                    setSelectCardType(event.target.value);
                  }}
                  style={{ minWidth: '250px' }}
                >
                  {selectCardList?.map((option ,idx) => {
                    return (
                      <MenuItem key={idx} value={option.value}>{option.lavel}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TableCell>

            <TableCell>
              상태
            </TableCell>
            <TableCell>
              <FormControl>
                <Select
                  size='small'
                  value={selectTrxStCdList.value}
                  defaultValue={selectTrxStCdType}
                  onChange={(event) => {
                    setSelectTrxStCdType(event.target.value);
                  }}
                  style={{ minWidth: '250px' }}
                >
                  {selectTrxStCdList?.map((option ,idx) => {
                    return (
                      <MenuItem key={idx} value={option.value}>{option.lavel}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              TID
            </TableCell>
            <TableCell>
              <TextField id="textTid" style={{ minWidth: '250px' }} variant="outlined" size="small" />
            </TableCell>
            <TableCell>
              승인번호
            </TableCell>
            <TableCell>
              <TextField id="textAppNo" style={{ minWidth: '250px' }} variant="outlined" size="small" />
            </TableCell>
            <TableCell>
              상품명
            </TableCell>
            <TableCell>
              <TextField id="textGoodsNm" style={{ minWidth: '250px' }} variant="outlined" size="small" />
            </TableCell>
            <TableCell>
              단말기번호
            </TableCell>
            <TableCell>
              <TextField id="textCatId" style={{ minWidth: '250px' }} variant="outlined" size="small" />
            </TableCell>
          </TableRow>
        </Table>
      </Card>
      <br></br>
      <br></br>
      <Card>
        <CardHeader title="거래내역 합계" />
        <Divider />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                총 승인건수
              </TableCell>
              <TableCell>
                총  승인금액
              </TableCell>
              <TableCell>
                승인건수
              </TableCell>
              <TableCell>
                승인금액
              </TableCell>
              <TableCell>
                취소건수
              </TableCell>
              <TableCell>
                취소금액
              </TableCell>
              <TableCell>
                건수합계
              </TableCell>
              <TableCell>
                금액합계
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {numberWithCommas(rowDataTotal.COUNT_GOODS_AMT)}
              </TableCell>
              <TableCell>
                {numberWithCommas(rowDataTotal.TOTAL_GOODS_AMT)}
              </TableCell>
              <TableCell>
                {numberWithCommas(rowDataTotal.COUNT_APP_GOODS_AMT)}
              </TableCell>
              <TableCell>
                {numberWithCommas(rowDataTotal.APP_GOODS_AMT)}
              </TableCell>
              <TableCell>
                {numberWithCommas(rowDataTotal.COUNT_CC_GOODS_AMT)}
              </TableCell>
              <TableCell>
                {numberWithCommas(rowDataTotal.CC_GOODS_AMT)}
              </TableCell>
              <TableCell>
                {numberWithCommas(rowDataTotal.COUNT_CC_GOODS_AMT + rowDataTotal.COUNT_GOODS_AMT ? rowDataTotal.COUNT_CC_GOODS_AMT + rowDataTotal.COUNT_GOODS_AMT : '0')}
              </TableCell>
              <TableCell>
                {numberWithCommas(rowDataTotal.TOTAL_GOODS_AMT + rowDataTotal.CC_GOODS_AMT ? rowDataTotal.TOTAL_GOODS_AMT + rowDataTotal.CC_GOODS_AMT : '0')}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <br></br>
      <br></br>
      <Card>
        <CardHeader title="거래내역" />
        <Divider />
        <div className="ag-theme-alpine" style={{ height: 520, width: '100%' }}>
          <AgGridReact rowHeight={rowHeight}
            frameworkComponents={{
              receiptButton: ReceiptButton,
              transDetailButton: TransDetailButton,
            }}
            defaultColDef={{ resizable: true }}
            debounceVerticalScrollbar={true}
            enableCellTextSelection={true}
            pagination={true}
            paginationPageSize={10}
            rowData={rowData}>
            <AgGridColumn width={120} headerName="결제일자" field="APP_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
            <AgGridColumn width={100} headerName="결제시간" field="APP_TM" cellRenderer={dateTimeGrid}></AgGridColumn>
            <AgGridColumn width={120} headerName="취소일자" field="CC_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
            <AgGridColumn width={100} headerName="취소시간" field="CC_TM" cellRenderer={dateTimeGrid}></AgGridColumn>
            <AgGridColumn width={100} headerName="PG" field="VAN_CD" cellRenderer={checkPgGrid}></AgGridColumn>
            <AgGridColumn width={120} headerName="MID" field="MID"></AgGridColumn>
            <AgGridColumn width={120} headerName="GID" field="GID"></AgGridColumn>
            <AgGridColumn width={150} headerName="상점명" field="CO_NM"></AgGridColumn>
            <AgGridColumn width={150} headerName="상품명" field="GOODS_NM"></AgGridColumn>
            <AgGridColumn width={100} headerName="결제금액" field="GOODS_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
            <AgGridColumn width={150} headerName="카드번호" field="CARD_NO"></AgGridColumn>
            <AgGridColumn width={100} headerName="할부" field="QUOTA_MON" cellRenderer={quotaMoanGrid}></AgGridColumn>
            <AgGridColumn width={280} headerName="주문번호" field="ORD_NO"></AgGridColumn>
            <AgGridColumn width={100} headerName="승인번호" field="APP_NO"></AgGridColumn>
            <AgGridColumn width={100} headerName="매입사" field="ACQ_CARD_NM"></AgGridColumn>
            <AgGridColumn width={100} headerName="상태" field="TRX_ST_CD" cellRenderer={transStatusGrid}></AgGridColumn>
            <AgGridColumn hide={l1vidDisplay} width={140} headerName="총판" field="L1_VID"></AgGridColumn>
            <AgGridColumn hide={l1vidDisplay} width={140} headerName="총판명" field="VGRP_NM"></AgGridColumn>
            <AgGridColumn hide={l2vidDisplay} width={140} headerName="대리점" field="L2_VID"></AgGridColumn>
            <AgGridColumn hide={l2vidDisplay} width={140} headerName="대리점명" field="VGRP_NM2"></AgGridColumn>
            <AgGridColumn hide={l3vidDisplay} width={140} headerName="딜러" field="L3_VID"></AgGridColumn>
            <AgGridColumn hide={l3vidDisplay} width={140} headerName="딜러명" field="VGRP_NM3"></AgGridColumn>
            <AgGridColumn width={280} headerName="TID" field="TID"></AgGridColumn>
            <AgGridColumn width={150} headerName="구매자" field="ORD_NM"></AgGridColumn>
            <AgGridColumn width={150} headerName="휴대폰" field="ORD_HP"></AgGridColumn>
            <AgGridColumn width={100} headerName="영수증" field="TID" cellRenderer="receiptButton"></AgGridColumn>
            <AgGridColumn width={100} headerName="취소" cellRenderer={cancelText} onCellClicked={(params) => cancel(params)}></AgGridColumn>
          </AgGridReact>
        </div>
      </Card>
    </>
  );
};


export default Trans;