import 'react-perfect-scrollbar/dist/css/styles.css';
import React ,{useState}  from 'react';
import {useRoutes} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import { useCookies } from 'react-cookie';

export const MyContext = React.createContext('');

const App = () => {
   
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const routing = useRoutes(routes(cookies.token !== undefined ? true : false));
  const [selectInfoList, setSelectInfoList] = useState({});

  return (
  <MyContext.Provider value={{ selectInfoList, setSelectInfoList }}>
      <ThemeProvider theme={theme}>
        {routing}
      </ThemeProvider>
      </MyContext.Provider>
  );
};

export default App;